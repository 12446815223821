import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import labels from '../../constants/LanguageLable';
import Label from '../../components/Form/Label';
import TextInput from '../../components/Form/TextInput';
import ButtonSection from '../../components/Form/ButtonSection';
import MultiDropdown from '../../components/Form/MultiDropdown';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';
export default function Edit({ history }) {
  const userType = [
    { id: 1, title: 'Passenger', value: 'passenger', label: 'Passenger' },
    { id: 2, title: 'Driver', value: 'driver', label: 'Driver' },
  ];
  const [languages, setLanguages] = useState([]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    let url = window.location.href;
    let ID = url.substring(url.lastIndexOf('/') + 1);
    configuration
      .getAPI({ url: 'language/list', params: { status: 'active' } })
      .then((data1) => {
        // console.log(data)
        if (data1.payload) {
          setLanguages(data1.payload);
          configuration
            .getAPI({ url: 'language-lable/list', params: { ID } })
            .then((data) => {
              // console.log(data)
              if (data.payload) {
                let questionObj = {};
                each(data1.payload, (lang, ind) => {
                  questionObj['value' + lang.code] = data.payload.value[lang.code] ? data.payload.value[lang.code] : '';
                });
                delete data.payload.value;
                setFields({ ...questionObj, ...data.payload });
              }
            })
            .catch((error) => {
              console.log(error);
              return toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        // console.log(error)
        return toast.error(error.message);
      });
  }, []);
  function validation() {
    let flag = true;
    let error = {};
    each(languages, (lang, ind) => {
      if (!fields['value' + lang.code]) {
        error['value' + lang.code] = 'Please enter Value in ' + lang.title;
        flag = false;
      }
    });
    // if (!fields.label_code) {
    //     error['label_code'] = "Please enter code"
    //     flag = false
    // }
    if (!fields.title) {
      error['title'] = 'Please enter title';
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      let questionObj = {};
      each(languages, (lang, ind) => {
        questionObj[lang.code] = fields['value' + lang.code] ? fields['value' + lang.code] : '';
        delete fields['value' + lang.code];
      });
      fields.value = questionObj;
      configuration
        .allAPI({ url: 'language-lable/update', method: 'PATCH', params: fields })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            history.push('/language-lable');
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error('Something went wrong');
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editLanguageLable} />
      <div className="main dark:bg-black bg-white mb-5">
        <div className="dark:bg-black bg-white">
          <div className="py-5 px-5 flex items-center justify-between">
            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.editLanguageLable}</h1>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full px-4">
            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
              <form className="space-y-6" action="#" method="POST">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div>
                    <Label title="Title" />
                    <TextInput
                      id={'title'}
                      name={'title'}
                      handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                      value={fields['title']}
                    />
                    {errors.title ? <Label title="title" fieldError={errors.title} /> : null}
                  </div>
                  <div>
                    <Label title="Code" />
                    <TextInput
                      id={'label_code'}
                      name={'label_code'}
                      handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                      value={fields['label_code']}
                      is_read_only={true}
                    />
                    {errors.label_code ? <Label title="label_code" fieldError={errors.label_code} /> : null}
                  </div>
                  <div>
                    <Label title="User Type" />
                    <MultiDropdown
                      id={'user_type'}
                      name={'user_type'}
                      value={fields['user_type']}
                      handleChange={(field, e) => setFields({ ...fields, [field]: e })}
                      options={userType}
                    />
                    {errors.label_code ? <Label title="label_code" fieldError={errors.label_code} /> : null}
                  </div>
                  {languages.map((single) => {
                    return (
                      <div>
                        <Label title={`Value [${single.title}]`} />
                        <TextInput
                          id={`value${single.code}`}
                          name={`value${single.code}`}
                          handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                          value={fields[`value${single.code}`]}
                        />
                        {errors[`value${single.code}`] ? <Label title={`value${single.code}`} fieldError={errors[`value${single.code}`]} /> : null}
                      </div>
                    );
                  })}
                </div>
                <ButtonSection path={'/language-lable'} handleSubmit={handleSubmit} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
