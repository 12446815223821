import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import React, { useState, useEffect } from 'react';
import configuration from '../../config';
import { toast } from 'react-toastify';
import Label from '../../components/Form/Label';
import TextInput from '../../components/Form/TextInput';
import { reactLocalStorage } from 'reactjs-localstorage';
// var jwt = require('jsonwebtoken');

export default function SignUp({ history }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  function validation() {
    let flag = true;
    let error = {};
    if (!fields.email) {
      error['email'] = 'Please enter email';
      flag = false;
    }
    if (!fields.password) {
      error['password'] = 'Please enter password';
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }
  function generateToken(user) {
    var object = {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      user_id: user._id,
      roleID: user.roleID,
      roleTitle: user.roleTitle,
      photo: user.photo ? user.photo : '',
    };
    // console.log(object)
    // return jwt.sign(object, configuration.appName, {
    //   expiresIn: '1d'
    // });
    return object;
  }
  useEffect(() => {
    try {
      var retrievedObject = JSON.parse(localStorage.getItem('token'));
      console.log(retrievedObject);
      if (retrievedObject) {
        history.push('/dashboard');
      }
    } catch (err) {
      history.push('/');
    }
    // var token = reactLocalStorage.get('token');
    // try {
    //   jwt.verify(token, configuration.appName, function (err, decoded) {
    //     if (err) {
    //       decoded = null;
    //       history.push("/")
    //     }
    //     else {
    //       history.push("/dashboard")
    //     }
    //   });
    // } catch (err) {
    //   history.push("/")
    // }
  }, []);

  const handleSubmit = () => {
    console.log('fields');
    if (validation()) {
      configuration
        .postAPI({ url: 'auth/sign-in', params: fields })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            reactLocalStorage.set('user_id', data.payload._id);
            reactLocalStorage.set('email', data.payload.email);
            var token = generateToken(data.payload);
            localStorage.setItem('token', JSON.stringify(token));
            //   reactLocalStorage.set('token', token);
            reactLocalStorage.set('loginlog_id', data.payload.loginlogID);
            history.push('/dashboard');
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error('Something went wrong');
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };
  return (
    <div className="flex dark:bg-black h-screen min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-28 w-auto" src={Logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-skin-primary">Sign in to your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-skin-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <Label title="Email address" />
              <div className="mt-1">
                <TextInput
                  id="email"
                  name="email"
                  handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                  value={fields.email}
                />
              </div>
              {errors.email ? <Label title="email" fieldError={errors.email} /> : null}
            </div>

            <div>
              <Label title="Password" />
              <div className="mt-1">
                <TextInput
                  id="password"
                  name="password"
                  handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                  value={fields.password}
                  is_password={true}
                />
              </div>
              {errors.password ? <Label title="password" fieldError={errors.password} /> : null}
            </div>

            <div className="flex items-center justify-end">
              <div className="text-base">
                <Link to="/forgot-password" className="font-medium text-skin-primary link">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="button"
                className="btn-link mt-1 flex justify-center w-full items-center rounded-md border-2 border-skin-primary px-5 py-2 text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white"
                onClick={handleSubmit}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
