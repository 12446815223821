import React, { useState, useEffect } from 'react';
import configuration from '../../../config';
import { toast } from 'react-toastify';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
let Id = '';
// List Settings
function Summary({ user_id }) {
  const [actions, setActions] = useState({});
  useEffect(() => {
    Id = user_id;
    configuration
      .getAPI({ url: 'app-user/detail', params: { user_id: Id } })
      .then((data) => {
        if (data.payload) {
          setActions(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              App Settings
            </h2>
            <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show user app settings.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="col-span-6 sm:col-span-8 mb-5">
              <div className="flex items-center">
                <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                  Location:
                </label>
                <p htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme capitalize">
                  {actions.is_location_enable ? 'Yes' : 'No'}
                </p>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-8">
              <div className="flex items-center">
                <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                  Notification:
                </label>
                <p htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme capitalize">
                  {actions.is_notification_enable ? 'Yes' : 'No'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
