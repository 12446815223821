import React from 'react';

// For Table Checkbox
function ColumnRow({ id, name, handleChange, checked, value }) {
  return (
    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">
      <input
        type="checkbox"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onClick={(e) => handleChange(e)}
        aria-describedby="comments-description"
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
    </td>
  );
}

export default ColumnRow;
