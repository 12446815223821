import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import labels from '../../constants/Brand';
import Label from '../../components/Form/Label';
import TextInput from '../../components/Form/TextInput';
import FileInput from '../../components/Form/FileInput';
import ButtonSection from '../../components/Form/ButtonSection';
import Dropdown from '../../components/Form/Dropdown';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from '../../components/Loader/Loader';

export default function Edit({ history }) {
  const options = [
    { id: 1, title: 'Active', value: 'active' },
    { id: 2, title: 'Inactive', value: 'inactive' },
  ];
  const userType = [
    { id: 1, title: 'Passenger', value: 'passenger' },
    { id: 2, title: 'Driver', value: 'driver' },
  ];
  const [languages, setLanguages] = useState([]);
  const [icon, setIcon] = useState(null);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [authorisation, setAuthorisation] = useState();

  useEffect(() => {
    let url = window.location.href;
    let ID = url.substring(url.lastIndexOf('/') + 1);
    configuration
      .getAPI({ url: 'language/list', params: { status: 'active' } })
      .then((data1) => {
        // console.log(data)
        if (data1.payload) {
          setLanguages(data1.payload);
          configuration
            .getAPI({ url: 'brand/list', params: { ID } })
            .then((data) => {
              // console.log(data)
              if (data.payload) {
                let titleObj = {};
                each(data1.payload, (lang, ind) => {
                  titleObj['title_' + lang.code] = data.payload.title[lang.code] ? data.payload.title[lang.code] : '';
                });
                delete data.payload.title;
                setFields({ ...titleObj, ...data.payload });
              }
            })
            .catch((error) => {
              return toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);
  function validation() {
    let flag = true;
    let error = {};
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = 'Please enter title in ' + lang.title;
        flag = false;
      }
    });
    if (!fields.status) {
      error['status'] = 'Please select status';
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      let titleObj = {};
      each(languages, (lang, ind) => {
        titleObj[lang.code] = fields['title_' + lang.code] ? fields['title_' + lang.code] : '';
        delete fields['title_' + lang.code];
      });
      fields.title = titleObj;
      const formData = new FormData();
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      let BaseFunction = 'allAPI';
      if (icon) {
        BaseFunction = 'postFormDataAPI';
        formData.append('icon', icon);
      }
      configuration[BaseFunction]({ url: 'brand/update', method: 'POST', params: icon ? formData : fields })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            history.push('/brand');
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error('Something went wrong');
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };
  function cancleImage(field, e) {
    setIcon(null);
    setFields({ ...fields, [field]: '' });
  }

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration
        .getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } })
        .then((data) => {
          if (data.payload) {
            setAuthorisation(data.payload);
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }, []);
  useEffect(() => {
    const whereFind = findWhere(authorisation, { code: 'BRAND' });
    if (whereFind?.data?.is_edit === false) {
      setIsValid(!isValid);
      history.push('/dashboard');
    }
    if (whereFind?.data?.is_edit === true) {
      setIsValid(!isValid);
    }
  }, [authorisation]);

  return (
    <>
      {' '}
      {isValid ? (
        <>
          {' '}
          <Breadcrumb module={labels.editBrand} />
          <div className="main dark:bg-black bg-white mb-5">
            <div className="dark:bg-black bg-white">
              <div className="py-5 px-5 flex items-center justify-between">
                <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.editBrand}</h1>
              </div>

              <div className="mt-5 sm:mx-auto sm:w-full px-4">
                <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                  <form className="space-y-6" action="#" method="POST">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                      {languages.map((single) => {
                        return (
                          <div>
                            <Label title={`Title [${single.title}]`} />
                            <TextInput
                              id={`title_${single.code}`}
                              name={`title_${single.code}`}
                              handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                              value={fields[`title_${single.code}`]}
                            />
                            {errors[`title_${single.code}`] ? (
                              <Label title={`title_${single.code}`} fieldError={errors[`title_${single.code}`]} />
                            ) : null}
                          </div>
                        );
                      })}
                      <div>
                        <Label title="Icon" />
                        <FileInput
                          id="icon"
                          name="icon"
                          value={fields.icon}
                          handleChange={(field, e) => {
                            setIcon(e.target.files[0]);
                            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) });
                          }}
                          cancleImage={cancleImage}
                        />
                        {errors.code ? <Label title="code" fieldError={errors.code} /> : null}
                      </div>
                      <div>
                        <Label title="Status" />
                        <Dropdown
                          id="status"
                          name="status"
                          title="status"
                          value={fields.status}
                          status={options}
                          handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                        />
                        {errors.status ? <Label title="status" fieldError={errors.status} /> : null}
                      </div>
                    </div>
                    <ButtonSection path={'/brand'} handleSubmit={handleSubmit} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Loader />{' '}
        </>
      )}
    </>
  );
}
