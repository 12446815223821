import React, { Fragment, useEffect } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import labels from '../../constants/Ride';
import { useState } from 'react';
import DataTable from '../../components/Table/MDataTable';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import ActionButton from '../../components/Other/ActionButton';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { pluck, findWhere } from 'underscore';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function View({ history }) {
  const btnGroups = [{ title: 'Export CSV', type: 'exportCSV', path: '/user', fileName: 'Trips' }];
  const [authorisation, setAuthorisation] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      title: 'ID',
      field: '_id',
      render: (rowData) =>
        findWhere(authorisation, { code: 'RIDE_DETAIL' })?.data?.is_view ? (
          <Link
            className="link text-skin-primary"
            to={{
              pathname: `/ride-detail`,
              state: rowData._id,
            }}
          >
            {rowData._id}
          </Link>
        ) : (
          <>{rowData._id}</>
        ),
    },
    { title: 'Customer', field: 'customer' },
    { title: 'Driver', field: 'driver' },
    { title: 'Service', field: 'service' },
    { title: 'Ride Option', field: 'rideOption' },
    { title: 'Ride Type', field: 'ride_type', render: (rowData) => <span className="capitalize">{rowData.ride_type}</span> },
    { title: 'Date', field: 'booking_date' },
  ];

  const onSelectionChange = (e) => {
    let idArr = pluck(e, '_id');
    setIds(idArr);
  };

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration
        .getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            setAuthorisation(data.payload);
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
    getDatas();
  }, []);
  function getDatas() {
    configuration
      .getAPI({ url: 'ride/list', params: { status: 'completed' } })
      .then((data) => {
        if (data.payload) {
          setData(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function handleAction(type) {
    if (ids.length < 1) {
      return toast.error('Please select at least one record');
    } else if (type === 'delete') {
      setShowModal(true);
      return;
    }
  }

  useEffect(() => {
    const whereFind = findWhere(authorisation, { code: 'COMPLATED_RIDE' });
    if (whereFind?.data?.is_view === false) {
      setIsValid(!isValid);
      history.push('/dashboard');
    }
    if (whereFind?.data?.is_view === true) {
      setIsValid(!isValid);
    }
  }, [authorisation]);

  return (
    <>
      {isValid ? (
        <>
          {' '}
          <Breadcrumb module={labels.completedRide} />
          <div className="main dark:bg-black bg-white mb-5">
            <div className="dark:bg-black bg-white">
              <div className="py-5 px-5 flex items-center justify-between">
                <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.completedRide}</h1>
                <div className="flex items-center gap-3">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#000] outline-none ">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon className="h-5 w-5 dark:text-white text-black" aria-hidden="true" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {btnGroups.map((btn) => {
                            return (
                              <ActionButton key={btn.id} btnTitle={btn.title} btnType={btn.type} btnPath={btn.path} handleAction={handleAction} />
                            );
                          })}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="dark:bg-black bg-white px-4 mt-5">
                <div className="mt-8 flex flex-col">
                  <div className="datatable">
                    <DataTable columns={columns} data={data} disabledCheckbox={true} onSelectionChange={onSelectionChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {' '}
          <Loader />{' '}
        </>
      )}
    </>
  );
}
