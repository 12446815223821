import React from 'react';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://cbad8490095a1f2550d9cec81192abc5@o4506080601178112.ingest.sentry.io/4506149593219073',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        React.useLocation,
        React.useNavigationType
        // TODO: Need upgraede `react-router-dom`
        // createRoutesFromChildren,
        // matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  environment: process.env.REACT_APP_ENV || 'development',
});

export { Sentry };
