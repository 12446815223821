import React, { useState, useEffect } from 'react';
import configuration from '../../../config';
import DataTable from '../../../components/Table/MDataTable';
import { toast } from 'react-toastify';
import { without } from 'underscore';

let Id = '';
// List Settings
function Summary({ user_id, user_type }) {
  // const [trips, setTrips] = useState([]);
  const columns = [
    { title: 'ID', field: '_id' },
    {
      title: 'Earning By',
      field: 'walletBy',
      render: (rowData) => <span className="capitalize">{rowData.walletBy}</span>,
    },
    { title: 'Total', field: 'amount' },
    { title: 'Date', field: 'createdAt' },
  ];
  const [data, setData] = useState([]);
  const [totalEarning, setTotalEarning] = useState(0);
  const onSelectionChange = (e) => {
    // let idArr = pluck(e, 'user_id');
    // setIds(idArr)
  };
  useEffect(() => {
    Id = user_id;
    configuration
      .getAPI({ url: 'app-user/earning', params: { user_id } })
      .then((data) => {
        if (data.payload) {
          setData(data.payload);
          setTotalEarning(data.payload[0].totalEarn);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Earning
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user earning.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <ul role="list" className="my-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-2">
              <li className="col-span-1 flex shadow-sm rounded-md">
                <div className="flex-shrink-0 flex items-center justify-center w-16 text-white border text-sm font-medium rounded-l-md">
                  <img className="h-5 w-5" src="/image/coins.png" alt="" />
                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                  <div className="flex-1 px-4 py-2 text-sm truncate">
                    <p className="text-skin-darkGray font-medium hover:text-skin-darkGray">Total Earnings</p>
                    <p className="text-skin-secondary text-lg">{totalEarning}</p>
                  </div>
                </div>
              </li>
            </ul>
            {/* <div className="ml-4 mt-4 flex">
              <h3 className="text-lg leading-6 font-medium text-gray-900">My Earnings</h3>
              <p className="mt-1 text-lg font-bold text-indigo-600">
                {totalEarning}
              </p>
            </div> */}
            <div className="datatable">
              <DataTable columns={without(columns, null)} data={data} disabledCheckbox={true} onSelectionChange={onSelectionChange} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
