import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MaterialReactTable } from 'material-react-table';
import tableIcons from './miui-icons';

// Table
function DataTable({ columns, data, onSelectionChange, tableMsg, disabledCheckbox }) {
  const [selectedRow] = useState(null);

  let pageSizeOptions = [];
  // addPush(5)
  addPush(10);
  addPush(20);
  addPush(50);
  addPush(100);
  addPush(200);
  addPush(500);
  addPush(1000);
  function addPush(number) {
    if (data.length > number) {
      pageSizeOptions.push(number);
    }
  }
  pageSizeOptions.push(data.length);

  // TODO: Migrate the column structure to material-react-table.
  const temporaryColumns = columns.map((item) => {
    return {
      ...item,
      header: item.title,
      accessorKey: item.field,
      // https://www.material-react-table.com/docs/api/column-options
      Cell: item.render
        // TODO: Update the original data. The old library is passing data like this: `{ "_id": "xxxxxxx" }`
        ? (column) => item.render({ [item.field]: column.renderedCellValue })
        : undefined
    };
  });

  return (
    <MaterialReactTable
      icons={tableIcons}
      columns={temporaryColumns}
      data={data}
      className="datatable"
      title={false}
      pagination
      paginationMode="server"
      localization={{
        body: {
          emptyDataSourceMessage: (
            <div className="block mx-auto py-10">
              <img className="norecord mx-auto py-5" src="/image/no-record.png" alt="no-record" /> No data found
            </div>
          ),
        },
      }}
      options={{
        emptyRowsWhenPaging: false,
        search: true,
        selection: disabledCheckbox ? false : true,
        selectionProps: (rowData) => ({
          disabled: rowData.isSelectDisabled,
          color: 'primary',
        }),
        sorting: true,
        pageSize: 10,
        pageSizeOptions: pageSizeOptions,
        rowStyle: (rowData) => ({
          backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
        }),
      }}
      onSelectionChange={onSelectionChange}
    />
  );
}

DataTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  onSelectionChange: PropTypes.func,
};

DataTable.defaultProps = {
  data: [],
  columns: [],
  onSelectionChange: () => {
    // do nothing.
  },
};

export default DataTable;
