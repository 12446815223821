import React from 'react';
import labels from '../constants/Footer';

// For Footer
function Footer() {
  return (
    <footer className="w-full bg-skin-white px-5 fixed bottom-0">
      <div className="py-3 text-center md:flex md:justify-between">
        <p className="text-base text-skin-black">&copy; {labels.allRightsReserved}</p>
      </div>
    </footer>
  );
}

export default Footer;
