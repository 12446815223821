import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Viewer from 'react-viewer';

// For Input Element
function TextInput({ id, name, handleChange, cancleImage, value, is_read_only = false }) {
  const [visiblePic, setVisiblePic] = useState(false);
  return (
    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      {value && value !== '' ? (
        <div className="space-y-1">
          <img name={name} id={id} src={value} width="100" height="100" />
          {!is_read_only ? (
            <button
              type="button"
              onClick={(e) => cancleImage(name, e)}
              className="btn-link ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md"
            >
              {' '}
              Remove
            </button>
          ) : (
            ''
          )}
          <button
            type="button"
            onClick={() => setVisiblePic(true)}
            className="btn-link ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md"
          >
            {' '}
            View
          </button>
          <Viewer visible={visiblePic} onClose={() => setVisiblePic(false)} images={[{ src: value, alt: name }]} />
        </div>
      ) : (
        <div className="space-y-1 text-center">
          <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-base text-gray-600">
            <label htmlFor={name} className="relative cursor-pointer bg-white rounded-md font-medium link text-skin-primary">
              <span>Upload a file</span>
              <input
                type="file"
                id={name}
                name={name}
                onChange={(e) => handleChange(name, e)}
                accept="image/*"
                autoComplete="given-name"
                className="sr-only"
              />
            </label>
            <p className="pl-1 text_theme">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500 text_theme">PNG, JPG, GIF up to 10MB</p>
        </div>
      )}
    </div>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

TextInput.defaultProps = {
  id: '',
  name: '',
};

export default TextInput;
