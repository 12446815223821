const Ride = {
  trip: 'Rides',
  newRide: 'New Ride',
  acceptedRide: 'Accepted Ride',
  startedRide: 'Started Ride',
  completedRide: 'Completed Ride',
  cancelledRide: 'Cancelled Ride',
  scheduledRide: 'Scheduled Ride',
  rideDetail: 'Ride Detail',
  rideInvoice: 'Ride Invoice',
};
export default Ride;
