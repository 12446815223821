import React, { useState, useEffect } from 'react';
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { contains } from 'underscore';

let Id = '';
// List Settings
function Summary() {
  const [services, setService] = useState([
    { service_id: '123', icon: '/image/money.png', title: 'CASH' },
    { service_id: '345', icon: '/image/vn.png', title: 'Wallet' },
    { service_id: '678', icon: '/image/vn.png', title: 'QR Scanner' },
  ]);
  const [selectedServices, setSelectedService] = useState(['123']);
  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf('/') + 1);
    // getData(Id);
    // console.log(configuration.baseURL);
  }, []);
  function getData(Id) {
    configuration
      .getAPI({ url: 'customer/detail', params: { user_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setSelectedService(data.payload.service_ids);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function handleSelect(serviceId, isSelected) {
    console.log(serviceId);
    setSelectedService([serviceId]);
  }
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Payment Method
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user payment method.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="services flex space-x-6 items-center">
              {services.map((service) => {
                const isSelected = contains(selectedServices, service.service_id);
                const mainClass = isSelected
                  ? 'border-gray-200 border px-3 py-3 text-center rounded-md w-28 cursor-pointer bg-skin-gray'
                  : 'border-gray-200 border px-3 py-3 text-center rounded-md w-28 cursor-pointer';
                const subClass = isSelected ? 'text-sm font-medium text-skin-secondary pt-2' : 'text-sm font-medium pt-2 text-gray-600';
                return (
                  <div onClick={() => handleSelect(service.service_id, isSelected)} className={mainClass}>
                    <div className="">
                      {/* <p as="span" className="block text-basefont-medium text-gray-900">
                                            <img className="mx-auto" src={service.icon} alt={service.title}/>
                                        </p> */}
                      <p as="span" className={subClass}>
                        {service.title}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
