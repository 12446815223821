import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Spinner from './components/Spinner';
import GuestRoute from './layout/GuestRoute';
import TemplateRoute from './layout/TemplateRoute';
import SignIn from './pages/signin/Signin';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ResetPassword from './pages/resetPassword/ResetPassword';
import CheckEmail from './pages/checkEmail/CheckEmail';
import Dashboard from './pages/dashboard/Dashboard';
import Adminland from './pages/setting/Adminland';
import personalSettings from './pages/setting/View';
import PageNotFound from './pages/404/View';

import Users from './pages/user/View';
import AddUsers from './pages/user/Add';
import EditUsers from './pages/user/Edit';
import LoginLog from './pages/user/LoginLog';
import Permission from './pages/user/Permission';

import AppUsers from './pages/appUser/View';
import AddAppUsers from './pages/appUser/Add';
import EditAppUsers from './pages/appUser/Edit';
import AppUsersProfile from './pages/appUser/Profile';
import ContactUs from './pages/appUser/ContactUs';

import Languages from './pages/language/View';
import AddLanguages from './pages/language/Add';
import EditLanguages from './pages/language/Edit';

import Taxes from './pages/tax/View';
import AddTaxes from './pages/tax/Add';
import EditTaxes from './pages/tax/Edit';

import Currency from './pages/currency/View';
import AddCurrency from './pages/currency/Add';
import EditCurrency from './pages/currency/Edit';

import CancelationReason from './pages/cancelationReason/View';
import AddCancelationReason from './pages/cancelationReason/Add';
import EditCancelationReason from './pages/cancelationReason/Edit';

import HelpCategory from './pages/helpCategory/View';
import AddHelpCategory from './pages/helpCategory/Add';
import EditHelpCategory from './pages/helpCategory/Edit';

import Help from './pages/help/View';
import AddHelp from './pages/help/Add';
import EditHelp from './pages/help/Edit';

import CMS from './pages/cms/View';
import AddCMS from './pages/cms/Add';
import EditCMS from './pages/cms/Edit';

import EmailTemplate from './pages/emailTemplate/View';
import AddEmailTemplate from './pages/emailTemplate/Add';
import EditEmailTemplate from './pages/emailTemplate/Edit';

import PushTemplate from './pages/pushTemplate/View';
import AddPushTemplate from './pages/pushTemplate/Add';
import EditPushTemplate from './pages/pushTemplate/Edit';

import SMSTemplate from './pages/smsTemplate/View';
import AddSMSTemplate from './pages/smsTemplate/Add';
import EditSMSTemplate from './pages/smsTemplate/Edit';

import LanguageLable from './pages/languageLable/View';
import AddLanguageLable from './pages/languageLable/Add';
import EditLanguageLable from './pages/languageLable/Edit';

import RatingCriteria from './pages/ratingCriteria/View';
import AddRatingCriteria from './pages/ratingCriteria/Add';
import EditRatingCriteria from './pages/ratingCriteria/Edit';

import Service from './pages/service/View';
import AddService from './pages/service/Add';
import EditService from './pages/service/Edit';

import Brand from './pages/brand/View';
import AddBrand from './pages/brand/Add';
import EditBrand from './pages/brand/Edit';

import RideOption from './pages/rideOption/View';
import AddRideOption from './pages/rideOption/Add';
import EditRideOption from './pages/rideOption/Edit';

import Subscription from './pages/subscription/View';
import AddSubscription from './pages/subscription/Add';
import EditSubscription from './pages/subscription/Edit';

import NewRide from './pages/rides/newRide';
import AcceptedRide from './pages/rides/acceptedRide';
import StartedRide from './pages/rides/startedRide';
import CompletedRide from './pages/rides/completedRide';
import CancelledRide from './pages/rides/cancelledRide';
import ScheduledRide from './pages/rides/scheduledRide';
import DetailRide from './pages/rides/detail';
import InvoiceRide from './pages/rides/invoice';

import Role from './pages/role/View';
import AddRole from './pages/role/Add';
import EditRole from './pages/role/Edit';

import Module from './pages/module/View';
import AddModule from './pages/module/Add';
import EditModule from './pages/module/Edit';

import Sales from './pages/report/sales';
import Profit from './pages/report/profit';
import TaxReport from './pages/report/tax';
import DriverEarning from './pages/report/driverEarning';
import CompanyEarning from './pages/report/companyEarning';

import Car from './pages/car/View';
import AddCar from './pages/car/Add';
import EditCar from './pages/car/Edit';

import Bank from './pages/bank/View';
import AddBank from './pages/bank/Add';
import EditBank from './pages/bank/Edit';

import Points from './pages/points/View';
import AddPoints from './pages/points/Add';
import EditPoints from './pages/points/Edit';

import ShareRideDiscount from './pages/ShareRideDiscount/View';
import AddShareRideDiscount from './pages/ShareRideDiscount/Add';
import EditShareRideDiscount from './pages/ShareRideDiscount/Edit';

import Tracker from './pages/Tracker/View';

function App() {
  return (
    <main className="">
      <div className="bg-white dark:bg-black h-screen">
        <Router>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <GuestRoute exact path="/" component={SignIn} />
              <GuestRoute exact path="/forgot-password" component={ForgotPassword} />
              <GuestRoute exact path="/reset-password/:id" component={ResetPassword} />
              <GuestRoute exact path="/check-email/:id" component={CheckEmail} />
              <TemplateRoute exact path="/dashboard" component={Dashboard} />
              <TemplateRoute exact path="/user" component={Users} />
              <TemplateRoute exact path="/user/add" component={AddUsers} />
              <TemplateRoute exact path="/user/edit/:id" component={EditUsers} />
              <TemplateRoute exact path="/login-log" component={LoginLog} />
              <TemplateRoute exact path="/permission" component={Permission} />
              <TemplateRoute exact path="/app-user" component={AppUsers} />
              <TemplateRoute exact path="/app-user/add" component={AddAppUsers} />
              <TemplateRoute exact path="/app-user/edit/:id" component={EditAppUsers} />
              <TemplateRoute exact path="/app-user/profile/:id" component={AppUsersProfile} />
              <TemplateRoute exact path="/contact-us" component={ContactUs} />
              <TemplateRoute exact path="/language" component={Languages} />
              <TemplateRoute exact path="/language/add" component={AddLanguages} />
              <TemplateRoute exact path="/language/edit/:id" component={EditLanguages} />
              <TemplateRoute exact path="/tax" component={Taxes} />
              <TemplateRoute exact path="/tax/add" component={AddTaxes} />
              <TemplateRoute exact path="/tax/edit/:id" component={EditTaxes} />
              <TemplateRoute exact path="/currency" component={Currency} />
              <TemplateRoute exact path="/currency/add" component={AddCurrency} />
              <TemplateRoute exact path="/currency/edit/:id" component={EditCurrency} />
              <TemplateRoute exact path="/cancelation-reason" component={CancelationReason} />
              <TemplateRoute exact path="/cancelation-reason/add" component={AddCancelationReason} />
              <TemplateRoute exact path="/cancelation-reason/edit/:id" component={EditCancelationReason} />
              <TemplateRoute exact path="/help-category" component={HelpCategory} />
              <TemplateRoute exact path="/help-category/add" component={AddHelpCategory} />
              <TemplateRoute exact path="/help-category/edit/:id" component={EditHelpCategory} />
              <TemplateRoute exact path="/help" component={Help} />
              <TemplateRoute exact path="/help/add" component={AddHelp} />
              <TemplateRoute exact path="/help/edit/:id" component={EditHelp} />
              <TemplateRoute exact path="/cms" component={CMS} />
              <TemplateRoute exact path="/cms/add" component={AddCMS} />
              <TemplateRoute exact path="/cms/edit/:id" component={EditCMS} />
              <TemplateRoute exact path="/email-template" component={EmailTemplate} />
              <TemplateRoute exact path="/email-template/add" component={AddEmailTemplate} />
              <TemplateRoute exact path="/email-template/edit/:id" component={EditEmailTemplate} />
              <TemplateRoute exact path="/push-template" component={PushTemplate} />
              <TemplateRoute exact path="/push-template/add" component={AddPushTemplate} />
              <TemplateRoute exact path="/push-template/edit/:id" component={EditPushTemplate} />
              <TemplateRoute exact path="/sms-template" component={SMSTemplate} />
              <TemplateRoute exact path="/sms-template/add" component={AddSMSTemplate} />
              <TemplateRoute exact path="/sms-template/edit/:id" component={EditSMSTemplate} />
              <TemplateRoute exact path="/language-lable" component={LanguageLable} />
              <TemplateRoute exact path="/language-lable/add" component={AddLanguageLable} />
              <TemplateRoute exact path="/language-lable/edit/:id" component={EditLanguageLable} />
              <TemplateRoute exact path="/rating-criteria" component={RatingCriteria} />
              <TemplateRoute exact path="/rating-criteria/add" component={AddRatingCriteria} />
              <TemplateRoute exact path="/rating-criteria/edit/:id" component={EditRatingCriteria} />
              <TemplateRoute exact path="/service" component={Service} />
              <TemplateRoute exact path="/service/add" component={AddService} />
              <TemplateRoute exact path="/service/edit/:id" component={EditService} />
              <TemplateRoute exact path="/brand" component={Brand} />
              <TemplateRoute exact path="/brand/add" component={AddBrand} />
              <TemplateRoute exact path="/brand/edit/:id" component={EditBrand} />
              <TemplateRoute exact path="/ride-option" component={RideOption} />
              <TemplateRoute exact path="/ride-option/add" component={AddRideOption} />
              <TemplateRoute exact path="/ride-option/edit/:id" component={EditRideOption} />
              <TemplateRoute exact path="/subscription" component={Subscription} />
              <TemplateRoute exact path="/subscription/add" component={AddSubscription} />
              <TemplateRoute exact path="/subscription/edit/:id" component={EditSubscription} />
              <TemplateRoute exact path="/adminland" component={Adminland} />
              <TemplateRoute exact path="/personal-settings" component={personalSettings} />
              <TemplateRoute exact path="/new-ride" component={NewRide} />
              <TemplateRoute exact path="/accepted-ride" component={AcceptedRide} />
              <TemplateRoute exact path="/started-ride" component={StartedRide} />
              <TemplateRoute exact path="/completed-ride" component={CompletedRide} />
              <TemplateRoute exact path="/cancelled-ride" component={CancelledRide} />
              <TemplateRoute exact path="/scheduled-ride" component={ScheduledRide} />
              <TemplateRoute exact path="/ride-detail" component={DetailRide} />
              <TemplateRoute exact path="/ride-invoice" component={InvoiceRide} />
              <TemplateRoute exact path="/role" component={Role} />
              <TemplateRoute exact path="/role/add" component={AddRole} />
              <TemplateRoute exact path="/role/edit/:id" component={EditRole} />
              <TemplateRoute exact path="/module" component={Module} />
              <TemplateRoute exact path="/module/add" component={AddModule} />
              <TemplateRoute exact path="/module/edit/:id" component={EditModule} />
              <TemplateRoute exact path="/sales" component={Sales} />
              <TemplateRoute exact path="/profit" component={Profit} />
              <TemplateRoute exact path="/tax-report" component={TaxReport} />
              <TemplateRoute exact path="/driver-earning" component={DriverEarning} />
              <TemplateRoute exact path="/company-earning" component={CompanyEarning} />
              <TemplateRoute exact path="/car" component={Car} />
              <TemplateRoute exact path="/car/add" component={AddCar} />
              <TemplateRoute exact path="/car/edit/:id" component={EditCar} />
              <TemplateRoute exact path="/bank" component={Bank} />
              <TemplateRoute exact path="/bank/add" component={AddBank} />
              <TemplateRoute exact path="/bank/edit/:id" component={EditBank} />
              <TemplateRoute exact path="/points" component={Points} />
              <TemplateRoute exact path="/points/add" component={AddPoints} />
              <TemplateRoute exact path="/points/edit/:id" component={EditPoints} />
              <TemplateRoute exact path="/share-ride-discount" component={ShareRideDiscount} />
              <TemplateRoute exact path="/share-ride-discount/add" component={AddShareRideDiscount} />
              <TemplateRoute exact path="/share-ride-discount/edit/:id" component={EditShareRideDiscount} />

              <TemplateRoute exact path="/tracker" component={Tracker} />

              <GuestRoute component={PageNotFound} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    </main>
  );
}
export default App;
