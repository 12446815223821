import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
// import { getAuth} from "firebase/auth";
import configuration from './config';
const firebaseConfig = {
  apiKey: configuration.google_key,
  authDomain: 'helical-loop-365412.firebaseapp.com',
  databaseURL: configuration.firebaseDBURL,
  projectId: 'helical-loop-365412',
  storageBucket: 'helical-loop-365412.appspot.com',
  messagingSenderId: '866515968312',
  appId: '1:866515968312:web:2f961a26f9eadf4a5921cd',
  measurementId: 'G-R5DCD6VKGF',
};
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
// const auth = getAuth(app);
export default database;
