import React from 'react';
import { Route } from 'react-router-dom';
import Template from './Template';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Route For Login User
function TemplateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Template>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            theme="colored"
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Component {...props} />
        </Template>
      )}
    />
  );
}

TemplateRoute.propTypes = {
  component: PropTypes.string,
};
TemplateRoute.defaultProps = {
  component: '',
};

export default TemplateRoute;
