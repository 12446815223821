import PropTypes from 'prop-types';
import React from 'react';

// For Label Element
function Label({ title, fieldError }) {
  return (
    <label className={`block ${fieldError ? 'text-sm text-skin-red' : 'text-base font-medium text-skin-black'}`}>
      {fieldError ? fieldError : title}
    </label>
  );
}

Label.propTypes = {
  title: PropTypes.string,
};

Label.defaultProps = {
  title: '',
};

export default Label;
