import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import React, { useState, useEffect } from 'react';
import configuration from '../../config';
import { toast } from 'react-toastify';
import Label from '../../components/Form/Label';
import TextInput from '../../components/Form/TextInput';
import { reactLocalStorage } from 'reactjs-localstorage';
// var jwt = require('jsonwebtoken');

export default function SignUp({ history }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  function validation() {
    let flag = true;
    let error = {};
    if (!fields.email) {
      error['email'] = 'Please enter email';
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }
  useEffect(() => {
    // try{
    //     var retrievedObject = JSON.parse(localStorage.getItem('token'));
    //     console.log(retrievedObject);
    //     if(retrievedObject){
    //         history.push("/dashboard")
    //     }
    // } catch(err){
    //     history.push("/")
    // }
  }, []);

  const handleSubmit = () => {
    console.log('fields');
    if (validation()) {
      configuration
        .postAPI({ url: 'auth/forgot-password', params: fields })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            history.push('/');
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error('Something went wrong');
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };
  return (
    <div className="flex dark:bg-black h-screen min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-28 w-auto" src={Logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-skin-primary">Sign in to your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-skin-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <Label title="Email address" />
              <div className="mt-1">
                <TextInput
                  id="email"
                  name="email"
                  handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                  value={fields.email}
                />
              </div>
              {errors.email ? <Label title="email" fieldError={errors.email} /> : null}
            </div>

            <div className="flex items-center justify-end">
              <div className="text-base">
                Just remembered? &nbsp;
                <Link to="/" className="font-medium text-skin-primary link">
                  Sign in
                </Link>
              </div>
            </div>

            <div>
              <button
                type="button"
                className="btn-link mt-1 flex justify-center w-full items-center rounded-md border-2 border-skin-primary px-5 py-2 text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white"
                onClick={handleSubmit}
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
