import React from 'react';
import { HomeIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import labels from '../constants/Breadcrumb';

// For Navigate
function Breadcrumb({ module }) {
  let breadcrumbArr = [];
  switch (module) {
    case 'Dashboard':
      breadcrumbArr = [{ name: 'Dashboard', href: '#', current: true }];
      break;
    case 'Adminland':
      breadcrumbArr = [{ name: 'Adminland', href: '#', current: true }];
      break;
    case 'Users':
      breadcrumbArr = [{ name: 'Users', href: '#', current: true }];
      break;
    case 'Add Users':
      breadcrumbArr = [
        { name: 'Users', href: '/app-user', current: false },
        { name: 'Add User', href: '#', current: true },
      ];
      break;
    case 'Edit Users':
      breadcrumbArr = [
        { name: 'Users', href: '/app-user', current: false },
        { name: 'Edit User', href: '#', current: true },
      ];
      break;
    case 'Contact Us':
      breadcrumbArr = [{ name: 'Contact Us', href: '#', current: true }];
      break;
    case 'Admin Users':
      breadcrumbArr = [{ name: 'Admin Users', href: '#', current: true }];
      break;
    case 'Add Admin  Users':
      breadcrumbArr = [
        { name: 'Admin Users', href: '/user', current: false },
        { name: 'Add Admin Users', href: '#', current: true },
      ];
      break;
    case 'Edit Admin Users':
      breadcrumbArr = [
        { name: 'Admin Users', href: '/user', current: false },
        { name: 'Edit Admin Users', href: '#', current: true },
      ];
      break;
    case 'Login Logs':
      breadcrumbArr = [{ name: 'Login Logs', href: '#', current: true }];
      break;
    case 'Languages':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Languages', href: '/#', current: true },
      ];
      break;
    case 'Add Languages':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Languages', href: '/language', current: false },
        { name: 'Add Language', href: '/#', current: true },
      ];
      break;
    case 'Edit Languages':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Languages', href: '/language', current: false },
        { name: 'Edit Language', href: '/#', current: true },
      ];
      break;
    case 'Currency':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Currency', href: '/#', current: true },
      ];
      break;
    case 'Add Currency':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Currency', href: '/currency', current: false },
        { name: 'Add Currency', href: '/#', current: true },
      ];
      break;
    case 'Edit Currency':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Currency', href: '/currency', current: false },
        { name: 'Edit Currency', href: '/#', current: true },
      ];
      break;
    case 'Taxes':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Taxes', href: '/#', current: true },
      ];
      break;
    case 'Add Taxes':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Taxes', href: '/tax', current: false },
        { name: 'Add Taxes', href: '/#', current: true },
      ];
      break;
    case 'Edit Taxes':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Taxes', href: '/tax', current: false },
        { name: 'Edit Taxes', href: '/#', current: true },
      ];
      break;
    case 'Services':
      breadcrumbArr = [{ name: 'Services', href: '/#', current: true }];
      break;
    case 'Add Services':
      breadcrumbArr = [
        { name: 'Services', href: '/service', current: false },
        { name: 'Add Services', href: '/#', current: true },
      ];
      break;
    case 'Edit Services':
      breadcrumbArr = [
        { name: 'Services', href: '/service', current: false },
        { name: 'Edit Services', href: '/#', current: true },
      ];
      break;
    case 'Brands':
      breadcrumbArr = [{ name: 'Brands', href: '/#', current: true }];
      break;
    case 'Add Brand':
      breadcrumbArr = [
        { name: 'Brands', href: '/brand', current: false },
        { name: 'Add Brand', href: '/#', current: true },
      ];
      break;
    case 'Edit Brand':
      breadcrumbArr = [
        { name: 'Brands', href: '/brand', current: false },
        { name: 'Edit Brand', href: '/#', current: true },
      ];
      break;

    case 'Cars':
      breadcrumbArr = [{ name: 'Cars', href: '/#', current: true }];
      break;

    case 'Add Car':
      breadcrumbArr = [
        { name: 'Car', href: '/car', current: false },
        { name: 'Add Car', href: '/#', current: true },
      ];
      break;

    case 'Edit Car':
      breadcrumbArr = [
        { name: 'Car', href: '/car', current: false },
        { name: 'Edit Car', href: '/#', current: true },
      ];
      break;

    case 'Vehicle Type':
      breadcrumbArr = [{ name: 'Vehicle Type', href: '/#', current: true }];
      break;
    case 'Add Vehicle Type':
      breadcrumbArr = [
        { name: 'Vehicle Type', href: '/ride-option', current: false },
        { name: 'Add Vehicle Type', href: '/#', current: true },
      ];
      break;
    case 'Edit Vehicle Type':
      breadcrumbArr = [
        { name: 'Vehicle Type', href: '/ride-option', current: false },
        { name: 'Edit Vehicle Type', href: '/#', current: true },
      ];
      break;
    case 'Rating Criteria':
      breadcrumbArr = [{ name: 'Rating Criteria', href: '/#', current: true }];
      break;
    case 'Add Rating Criteria':
      breadcrumbArr = [
        { name: 'Rating Criteria', href: '/rating-criteria', current: false },
        { name: 'Add Rating Criteria', href: '/#', current: true },
      ];
      break;
    case 'Edit Rating Criteria':
      breadcrumbArr = [
        { name: 'Rating Criteria', href: '/rating-criteria', current: false },
        { name: 'Edit Rating Criteria', href: '/#', current: true },
      ];
      break;
    case 'Cancelation Reason':
      breadcrumbArr = [{ name: 'Cancelation Reason', href: '/#', current: true }];
      break;
    case 'Add Cancelation Reason':
      breadcrumbArr = [
        {
          name: 'Cancelation Reason',
          href: '/cancelation-reason',
          current: false,
        },
        { name: 'Add Cancelation Reason', href: '/#', current: true },
      ];
      break;
    case 'Edit Cancelation Reason':
      breadcrumbArr = [
        {
          name: 'Cancelation Reason',
          href: '/cancelation-reason',
          current: false,
        },
        { name: 'Edit Cancelation Reason', href: '/#', current: true },
      ];
      break;
    case 'Help Category':
      breadcrumbArr = [{ name: 'Help Category', href: '/#', current: true }];
      break;
    case 'Add Help Category':
      breadcrumbArr = [
        { name: 'Help Category', href: '/help-category', current: false },
        { name: 'Add Help Category', href: '/#', current: true },
      ];
      break;
    case 'Edit Help Category':
      breadcrumbArr = [
        { name: 'Help Category', href: '/help-category', current: false },
        { name: 'Edit Help Category', href: '/#', current: true },
      ];
      break;
    case 'Help':
      breadcrumbArr = [{ name: 'Help', href: '/#', current: true }];
      break;
    case 'Add Help':
      breadcrumbArr = [
        { name: 'Help', href: '/help', current: false },
        { name: 'Add Help', href: '/#', current: true },
      ];
      break;
    case 'Edit Help':
      breadcrumbArr = [
        { name: 'Help', href: '/help', current: false },
        { name: 'Edit Help', href: '/#', current: true },
      ];
      break;
    case 'CMS':
      breadcrumbArr = [{ name: 'CMS', href: '/#', current: true }];
      break;
    case 'Add CMS':
      breadcrumbArr = [
        { name: 'CMS', href: '/cms', current: false },
        { name: 'Add CMS', href: '/#', current: true },
      ];
      break;
    case 'Edit CMS':
      breadcrumbArr = [
        { name: 'CMS', href: '/cms', current: false },
        { name: 'Edit CMS', href: '/#', current: true },
      ];
      break;
    case 'Email Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Email Template', href: '/#', current: true },
      ];
      break;
    case 'Add Email Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Email Template', href: '/email-template', current: false },
        { name: 'Add Email Template', href: '/#', current: true },
      ];
      break;
    case 'Edit Email Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Email Template', href: '/email-template', current: false },
        { name: 'Edit Email Template', href: '/#', current: true },
      ];
      break;
    case 'Push Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Push Template', href: '/#', current: true },
      ];
      break;
    case 'Add Push Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Push Template', href: '/push-template', current: false },
        { name: 'Add Push Template', href: '/#', current: true },
      ];
      break;
    case 'Edit Push Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'Push Template', href: '/push-template', current: false },
        { name: 'Edit Push Template', href: '/#', current: true },
      ];
      break;
    case 'SMS Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'SMS Template', href: '/#', current: true },
      ];
      break;
    case 'Add SMS Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'SMS Template', href: '/sms-template', current: false },
        { name: 'Add SMS Template', href: '/#', current: true },
      ];
      break;
    case 'Edit SMS Template':
      breadcrumbArr = [
        { name: 'Adminland', href: '/adminland', current: false },
        { name: 'SMS Template', href: '/sms-template', current: false },
        { name: 'Edit SMS Template', href: '/#', current: true },
      ];
      break;
    case 'Subscriptions':
      breadcrumbArr = [{ name: 'Subscriptions', href: '/#', current: true }];
      break;
    case 'Add Subscription':
      breadcrumbArr = [
        { name: 'Subscriptions', href: '/subscription', current: false },
        { name: 'Add Subscription', href: '/#', current: true },
      ];
      break;
    case 'Edit Subscription':
      breadcrumbArr = [
        { name: 'Subscriptions', href: '/subscription', current: false },
        { name: 'Edit Subscription', href: '/#', current: true },
      ];
      break;
    case 'New Ride':
      breadcrumbArr = [{ name: 'New Ride', href: '#', current: true }];
      break;
    case 'Accepted Ride':
      breadcrumbArr = [{ name: 'Accepted Ride', href: '#', current: true }];
      break;
    case 'Started Ride':
      breadcrumbArr = [{ name: 'Started Ride', href: '#', current: true }];
      break;
    case 'Completed Ride':
      breadcrumbArr = [{ name: 'Completed Ride', href: '#', current: true }];
      break;
    case 'Cancelled Ride':
      breadcrumbArr = [{ name: 'Cancelled Ride', href: '#', current: true }];
      break;
    case 'Scheduled Ride':
      breadcrumbArr = [{ name: 'Scheduled Ride', href: '#', current: true }];
      break;
    case 'Ride Detail':
      breadcrumbArr = [{ name: 'Ride Detail', href: '#', current: true }];
      break;
    case 'Ride Invoice':
      breadcrumbArr = [{ name: 'Ride Invoice', href: '#', current: true }];
      break;
    case 'Sales Revenue':
      breadcrumbArr = [{ name: 'Sales Revenue', href: '#', current: true }];
      break;
    case 'Net Profits':
      breadcrumbArr = [{ name: 'Net Profits', href: '#', current: true }];
      break;
    case 'Tax Report':
      breadcrumbArr = [{ name: 'Tax Report', href: '#', current: true }];
      break;
    case 'Driver Earning':
      breadcrumbArr = [{ name: 'Driver Earning', href: '#', current: true }];
      break;
    case 'Company Earning':
      breadcrumbArr = [{ name: 'Company Earning', href: '#', current: true }];
      break;
    case 'Role':
      breadcrumbArr = [{ name: 'Role', href: '/#', current: true }];
      break;
    case 'Add Role':
      breadcrumbArr = [
        { name: 'Role', href: '/role', current: false },
        { name: 'Add Role', href: '/#', current: true },
      ];
      break;
    case 'Edit Role':
      breadcrumbArr = [
        { name: 'Role', href: '/role', current: false },
        { name: 'Edit Role', href: '/#', current: true },
      ];
      break;
    case 'Bank':
      breadcrumbArr = [{ name: 'Bank', href: '/#', current: true }];
      break;
    case 'Add Bank':
      breadcrumbArr = [
        { name: 'Bank', href: '/bank', current: false },
        { name: 'Add Bank', href: '/#', current: true },
      ];
      break;
    case 'Edit Bank':
      breadcrumbArr = [
        { name: 'Bank', href: '/bank', current: false },
        { name: 'Edit Bank', href: '/#', current: true },
      ];
      break;
    case 'Points':
      breadcrumbArr = [{ name: 'Points', href: '/#', current: true }];
      break;
    case 'Add Points':
      breadcrumbArr = [
        { name: 'Points', href: '/points', current: false },
        { name: 'Add Points', href: '/#', current: true },
      ];
      break;
    case 'Edit Points':
      breadcrumbArr = [
        { name: 'Points', href: '/points', current: false },
        { name: 'Edit Points', href: '/#', current: true },
      ];
      break;
    case 'Share Ride Discount':
      breadcrumbArr = [{ name: 'Share Ride Discount', href: '/#', current: true }];
      break;
    case 'Add Share Ride Discount':
      breadcrumbArr = [
        { name: 'Share Ride Discount', href: '/share-ride-discount', current: false },
        { name: 'Add Share Ride Discount', href: '/#', current: true },
      ];
      break;
    case 'Edit Share Ride Discount':
      breadcrumbArr = [
        { name: 'Share Ride Discount', href: '/share-ride-discount', current: false },
        { name: 'Edit Share Ride Discount', href: '/#', current: true },
      ];
      break;
    default:
      breadcrumbArr = [{ name: 'Adminland', href: '#', current: true }];
      break;
  }

  return (
    <nav className="py-2 border-t border-skin-light flex xl-breadcrumb breadcrumb_theme" aria-label="Breadcrumb">
      <ol
        // eslint-disable-next-line jsx-a11y/aria-role
        role="listData"
        className="max-w-screen-xl w-full px-4 flex space-x-4 sm:px-6 lg:px-8"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link to="/dashboard" className="text-skin-secondary ">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{labels.home}</span>
            </Link>
          </div>
        </li>
        {breadcrumbArr.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-4 w-4 flex-shrink-0 text-skin-secondary"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"></path>
              </svg>
              {page.current ? (
                <span className="text-sm font-medium text-skin-primary" aria-current={page.current ? 'page' : null}>
                  {page.name}
                </span>
              ) : (
                <Link to={page.href} className="text-sm font-medium text-skin-secondary" aria-current={page.current ? 'page' : null}>
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
