import { CogIcon, UserCircleIcon, BuildingOfficeIcon, LanguageIcon, TicketIcon, TruckIcon, GiftIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import Breadcrumb from '../../components/breadcrumb';
import BtnSaveRecord from '../../components/Form/Button/BtnSaveRecord';
import Label from '../../components/Form/Label';
import TextInput from '../../components/Form/TextInput';
import Dropdown from '../../components/Form/Dropdown';
import _, { each, findWhere } from 'underscore';
import labels from '../../constants/Setting';
import { Link } from 'react-router-dom';
import configuration from '../../config';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { useHistory } from 'react-router-dom';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
// List Settings
function View() {
  const [subNavigation, setSubNavigation] = useState([
    { name: 'General', href: '#', icon: UserCircleIcon, current: true },
    {
      name: 'Company Info',
      href: '#',
      icon: BuildingOfficeIcon,
      current: false,
    },
    {
      name: 'Language & Region',
      href: '#',
      icon: LanguageIcon,
      current: false,
    },
    { name: 'Passenger Referral', href: '#', icon: TicketIcon, current: false },
    { name: 'Driver Referral', href: '#', icon: TicketIcon, current: false },
    { name: 'Trip Settings', href: '#', icon: TruckIcon, current: false },
    { name: 'Other Settings', href: '#', icon: CogIcon, current: false },
    // { name: "Point Settings", href: "#", icon: GiftIcon, current: false },
  ]);
  const [tab, setTab] = useState(0);
  const [fields, setFields] = useState();
  /* const [totalPoints, setTotalPoints] = useState(0);
  const [country, setCountry] = useState([]); */
  const [language, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [authorisation, setAuthorisation] = useState();
  const history = useHistory();

  const dateFormate = [
    { id: 0, value: 'DD-MM-YYYY', title: 'DD-MM-YYYY [01-08-2019]' },
    { id: 1, value: 'DD/MM/YYYY', title: 'DD/MM/YYYY [01/08/2019]' },
    { id: 2, value: 'DD-MMM-YYYY', title: 'DD-MMM-YYYY [01-Aug-2019]' },
    { id: 3, value: 'DD-MMM-YY', title: 'DD-MMM-YY [01-Aug-19]' },
    { id: 4, value: 'YYYY-MM-DD', title: 'YYYY-MM-DD [2019-08-01]' },
    { id: 5, value: 'MM-DD-YY', title: 'MM-DD-YY [08-01-19]' },
    {
      id: 6,
      value: 'MMMM Do, YYYY',
      title: 'MMMM Do, YYYY [August 1st, 2019]',
    },
    { id: 7, value: 'M/D/YYYY', title: 'M/D/YYYY [8/1/2019]' },
  ];
  const timers = [
    { id: 0, value: 'minutes', title: 'Minutes' },
    { id: 1, value: 'seconds', title: 'Seconds' },
  ];
  const [errors, setErrors] = useState(0);
  function setTabMenu(index) {
    setTab(index);
    const navigate = subNavigation;
    _.each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }
  useEffect(() => {
    configuration
      .getAPI({ url: 'language/list', params: { status: 'active' } })
      .then((data1) => {
        each(data1.payload, (single) => {
          single.value = single._id;
        });
        setLanguage(data1.payload);
        configuration
          .getAPI({ url: 'common/detail', params: {} })
          .then((data) => {
            if (data.payload) {
              let newFields = data.payload;
              if (newFields.passenger_free_ride) {
                newFields.inviter_discount_amount = newFields.passenger_free_ride.inviter_discount_amount;
                newFields.invitee_discount_amount = newFields.passenger_free_ride.invitee_discount_amount;
                each(data1.payload, (single) => {
                  newFields[`app_caption_${single.code}`] = newFields.passenger_free_ride
                    ? newFields.passenger_free_ride.app_caption[`${single.code}`]
                    : '';
                  newFields[`app_share_caption_${single.code}`] = newFields.passenger_free_ride
                    ? newFields.passenger_free_ride.app_share_caption[`${single.code}`]
                    : '';
                });
              }
              if (newFields.driver_refer) {
                newFields.inviter_bonus_amount = newFields.driver_refer.inviter_bonus_amount || 0;
                newFields.invitee_bonus_amount = newFields.driver_refer.invitee_bonus_amount || 0;
                newFields.no_of_rides_completion = newFields.driver_refer.no_of_rides_completion;
                each(data1.payload, (single) => {
                  newFields[`driver_app_caption_${single.code}`] = newFields.driver_refer ? newFields.driver_refer.app_caption[`${single.code}`] : '';
                  newFields[`driver_app_share_caption_${single.code}`] = newFields.driver_refer
                    ? newFields.driver_refer.app_share_caption[`${single.code}`]
                    : '';

                  newFields[`pointsDescription_${single.code}`] = newFields.pointsDescription ? newFields.pointsDescription[`${single.code}`] : '';
                });
              }
              if (newFields.driver_timer) {
                newFields.driver_timer_type = newFields.driver_timer.type;
                newFields.driver_timer_value = newFields.driver_timer.value;
              }
              if (newFields.schedule_trip_time) {
                newFields.schedule_trip_time_type = newFields.schedule_trip_time.type;
                newFields.schedule_trip_time_value = newFields.schedule_trip_time.value;
              }
              setFields(newFields);
              document.title = data.payload.app_name;
            }
          })
          .catch((error) => {
            return toast.error(error.message);
          });
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    // configuration.getAPI({ url: "country/list", params: { status: 'active' } }).then((data) => {
    //   // setCountry(data.payload)
    // }).catch((error) => {
    //   return toast.error(error.message);
    // });

    configuration
      .getAPI({ url: 'currency/list', params: { status: 'active' } })
      .then((data) => {
        each(data.payload, (single) => {
          single.value = single._id;
        });
        setCurrency(data.payload);
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);
  function onChangeSwitch(e, type) {
    console.log(type);
    setFields({ ...fields, [type]: e });
  }
  function handleChange(field, e) {
    console.log(e.target.value, typeof e.target.value);
    setFields({ ...fields, [field]: e.target.value });
  }
  function validation() {
    let flag = true;
    let error = {};
    if (!fields.app_name) {
      error['app_name'] = 'Please enter Platform name';
      flag = false;
    }
    // if (!fields.default_country_id) {
    //   error['default_country_id'] = "Please select default country"
    //   flag = false
    // }
    // if (!fields.default_country_id) {
    //   error['default_country_id'] = "Please select default country"
    //   flag = false
    // }

    if (!fields.copy_right_text) {
      error['copy_right_text'] = 'Please enter copy right text';
      flag = false;
    }
    if (!fields.company_website) {
      error['company_website'] = 'Please enter company website';
      flag = false;
    }
    if (!fields.company_address) {
      error['company_address'] = 'Please enter company address';
      flag = false;
    }
    if (!fields.company_phone_number) {
      error['company_phone_number'] = 'Please enter company phone number';
      flag = false;
    }
    if (!fields.support_email) {
      error['support_email'] = 'Please enter support email';
      flag = false;
    }
    if (!fields.support_phone_number) {
      error['support_phone_number'] = 'Please enter support phone number';
      flag = false;
    }
    if (fields.company_commission < 0 || fields.company_commission > 100) {
      error['company_commission'] = 'Please enter valid company commission';
      flag = false;
    }
    if (fields.premium_badge_value < 1 || fields.premium_badge_value > 5) {
      error['premium_badge_value'] = 'Please enter badge value between 1 to 5';
      flag = false;
    }
    if (fields.plus_badge_value < 1 || fields.plus_badge_value > 5) {
      error['plus_badge_value'] = 'Please enter badge value between 1 to 5';
      flag = false;
    }
    if (fields.driver_standard_badge_value < 1 || fields.driver_standard_badge_value > 5) {
      error['driver_standard_badge_value'] = 'Please enter badge value between 1 to 5';
      flag = false;
    }
    if (fields.standard_badge_value < 1 || fields.standard_badge_value > 5) {
      error['standard_badge_value'] = 'Please enter badge value between 1 to 5';
      flag = false;
    }
    if (fields.silver_badge_value < 1 || fields.silver_badge_value > 5) {
      error['silver_badge_value'] = 'Please enter badge value between 1 to 5';
      flag = false;
    }
    if (fields.golden_badge_value < 1 || fields.golden_badge_value > 5) {
      error['golden_badge_value'] = 'Please enter badge value between 1 to 5';
      flag = false;
    }
    if (!fields.bookingFees) {
      error['bookingFees'] = 'Please enter booking fees';
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }
  const handleSubmit = () => {
    if (validation()) {
      console.log(fields);
      let app_caption = {};
      let app_share_caption = {};
      let driver_app_caption = {};
      let driver_app_share_caption = {};
      let pointsDescription = {};
      each(language, (single) => {
        app_caption[single.code] = fields[`app_caption_${single.code}`] ? fields[`app_caption_${single.code}`] : '';
        app_share_caption[single.code] = fields[`app_share_caption_${single.code}`] ? fields[`app_share_caption_${single.code}`] : '';
        driver_app_caption[single.code] = fields[`driver_app_caption_${single.code}`] ? fields[`driver_app_caption_${single.code}`] : '';
        driver_app_share_caption[single.code] = fields[`driver_app_share_caption_${single.code}`]
          ? fields[`driver_app_share_caption_${single.code}`]
          : '';
        pointsDescription[single.code] = fields[`pointsDescription_${single.code}`] ? fields[`pointsDescription_${single.code}`] : '';
      });
      let driver_refer = fields?.driver_refer || {};
      driver_refer.inviter_bonus_amount = fields?.inviter_bonus_amount || 0;
      driver_refer.invitee_bonus_amount = fields?.invitee_bonus_amount || 0;
      driver_refer.no_of_rides_completion = fields?.no_of_rides_completion;
      driver_refer.app_caption = driver_app_caption;
      driver_refer.app_share_caption = driver_app_share_caption;

      let passenger_free_ride = fields?.passenger_free_ride || {};
      passenger_free_ride.inviter_discount_amount = fields?.inviter_discount_amount || 0;
      passenger_free_ride.invitee_discount_amount = fields?.invitee_discount_amount || 0;
      passenger_free_ride.app_caption = app_caption;
      passenger_free_ride.app_share_caption = app_share_caption;

      let driver_timer = fields?.driver_timer || {};
      driver_timer.type = fields.driver_timer_type;
      driver_timer.value = fields.driver_timer_value;

      let schedule_trip_time = fields?.schedule_trip_time || {};
      schedule_trip_time.type = fields.schedule_trip_time_type;
      schedule_trip_time.value = fields.schedule_trip_time_value;

      fields.driver_refer = driver_refer;
      fields.passenger_free_ride = passenger_free_ride;
      fields.schedule_trip_time = schedule_trip_time;
      fields.driver_timer = driver_timer;
      fields.pointsDescription = pointsDescription;

      configuration
        .allAPI({ url: 'common/update-data', params: fields, method: 'PUT' })
        .then((data) => {
          // console.log(data)
          if (data.payload.userUpdate) {
            return toast.success('Setting Updated Successfully');
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error('Something went wrong');
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration
        .getAPI({
          url: 'common/list-access',
          params: { roleID: retrievedObject.roleID },
        })
        .then((data) => {
          if (data.payload) {
            setAuthorisation(data.payload);
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }, []);
  useEffect(() => {
    const whereFind = findWhere(authorisation, { code: 'SETTING' });
    if (whereFind?.data?.is_add === false) {
      setIsValid(!isValid);
      history.push('/dashboard');
    }
    if (whereFind?.data?.is_add === true) {
      setIsValid(!isValid);
    }
  }, [authorisation]);

  return (
    <>
      {isValid ? (
        <>
          {' '}
          <Breadcrumb module={labels.setting} />
          <div className="py-6 sm:px-6">
            <div className="relative">
              <section className="px-4" aria-labelledby="contact-heading">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                        <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">Personal Settings</h2>
                        </div>
                      </div>
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                        <main className="relative">
                          <div className="mx-auto">
                            <div className="bg-white rounded-lg shadow overflow-hidden box_bg">
                              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                                <aside className="py-6 lg:col-span-2">
                                  <nav className="space-y-1">
                                    {subNavigation.map((item, index) => (
                                      <Link
                                        to="#"
                                        key={item.name}
                                        onClick={() => setTabMenu(index)}
                                        onKeyDown={() => setTabMenu(index)}
                                        className={classNames(
                                          item.current
                                            ? 'bg-indigo-50 text-indigo-700 text-teal-700 hover:bg-teal-50 hover:text-teal-700 border-indigo-600 input_theme'
                                            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-500 text_theme',
                                          'group border-l-4 px-3 py-2 flex items-center text-base xl-admin-btn'
                                        )}
                                        aria-current={item.current ? 'page' : null}
                                      >
                                        <item.icon
                                          className={classNames(
                                            item.current ? 'text-teal-500 group-hover:text-teal-500' : 'text-gray-400 group-hover:text-gray-500',
                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                          )}
                                          aria-hidden="true"
                                        />
                                        <span className="truncate">{item.name}</span>
                                      </Link>
                                    ))}
                                  </nav>
                                </aside>
                                {tab === 0 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">General</h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">
                                        This information will be displayed publicly, so be careful while adding the details.
                                      </p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="col-span-2">
                                          <Label title="Platform Name" />
                                          <TextInput
                                            id="app_name"
                                            name="app_name"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields?.app_name}
                                          />
                                          {errors.app_name ? <Label title="app_name" fieldError={errors.app_name} /> : null}
                                        </div>

                                        {/* <div className="">
                                    <Label title="Default Country" />
                                    <Dropdown
                                      id="default_country_id"
                                      name="default_country_id"
                                      title="default_country_id"
                                      value={fields.default_country_id}
                                      status={country}
                                      handleChange={(field, e) =>
                                        setFields({ ...fields, [field]: e.target.value })}
                                    />
                                    {errors.default_country_id ? <Label title='default_country_id' fieldError={errors.default_country_id} /> : null}
                                  </div> */}

                                        <div className="">
                                          <Label title="App Default Language" />
                                          <Dropdown
                                            id="app_language_id"
                                            name="app_language_id"
                                            title="app_language_id"
                                            value={fields?.app_language_id}
                                            status={language}
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                          />
                                          {errors.app_language_id ? <Label title="app_language_id" fieldError={errors.app_language_id} /> : null}
                                        </div>

                                        <div className="">
                                          <Label title="Default Currency" />
                                          <Dropdown
                                            id="default_currency_id"
                                            name="default_currency_id"
                                            title="default_currency_id"
                                            value={fields?.default_currency_id}
                                            status={currency}
                                            handleChange={(field, e) => {
                                              console.log(field);
                                              console.log(e.target.value);
                                              let currencyData = findWhere(currency, { _id: e.target.value });
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                                currency: currencyData ? currencyData.symbol : '',
                                              });
                                            }}
                                          />
                                          {errors.default_currency_id ? (
                                            <Label title="default_currency_id" fieldError={errors.default_currency_id} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Copy Right Text" />
                                          <TextInput
                                            id="copy_right_text"
                                            name="copy_right_text"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields?.copy_right_text}
                                          />
                                          {errors.copy_right_text ? <Label title="copy_right_text" fieldError={errors.copy_right_text} /> : null}
                                        </div>

                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={'/'} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {tab === 1 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Company Info</h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">From here you can add/edit your company details.</p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="">
                                          <Label title="Company Website" />
                                          <TextInput
                                            id="company_website"
                                            name="company_website"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.company_website}
                                          />
                                          {errors.company_website ? <Label title="company_website" fieldError={errors.company_website} /> : null}
                                        </div>

                                        <div className="">
                                          <Label title="Company Address" />
                                          <TextInput
                                            id="company_address"
                                            name="company_address"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.company_address}
                                          />
                                          {errors.company_address ? <Label title="company_address" fieldError={errors.company_address} /> : null}
                                        </div>

                                        <div className=" relative">
                                          <Label title="Company Phone" />
                                          <TextInput
                                            id="company_phone_number"
                                            name="company_phone_number"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.company_phone_number}
                                            is_number={true}
                                          />
                                          {errors.company_phone_number ? (
                                            <Label title="company_phone_number" fieldError={errors.company_phone_number} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Support Email" />
                                          <TextInput
                                            id="support_email"
                                            name="support_email"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.support_email}
                                            is_number={false}
                                          />
                                          {errors.support_email ? <Label title="support_email" fieldError={errors.support_email} /> : null}
                                        </div>

                                        <div className="">
                                          <Label title="Support Phone" />
                                          <TextInput
                                            id="support_phone_number"
                                            name="support_phone_number"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.support_phone_number}
                                            is_number={true}
                                          />
                                          {errors.support_phone_number ? (
                                            <Label title="support_phone_number" fieldError={errors.support_phone_number} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Facebook URL" />
                                          <TextInput
                                            id="fb_url"
                                            name="fb_url"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.fb_url}
                                            is_number={false}
                                          />
                                          {errors.fb_url ? <Label title="fb_url" fieldError={errors.fb_url} /> : null}
                                        </div>

                                        <div className="">
                                          <Label title="Twitter URL" />
                                          <TextInput
                                            id="twitter_url"
                                            name="twitter_url"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.twitter_url}
                                            is_number={false}
                                          />
                                          {errors.twitter_url ? <Label title="twitter_url" fieldError={errors.twitter_url} /> : null}
                                        </div>

                                        <div className="">
                                          <Label title="Instagram URL" />
                                          <TextInput
                                            id="instagram_url"
                                            name="instagram_url"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.instagram_url}
                                            is_number={false}
                                          />
                                          {errors.instagram_url ? <Label title="instagram_url" fieldError={errors.instagram_url} /> : null}
                                        </div>

                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={'/'} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {tab === 2 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Language & Region</h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">
                                        This information will be displayed publicly, so be careful while adding the details.
                                      </p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="">
                                          <Label title="Language" />
                                          <Dropdown
                                            id="default_language_id"
                                            name="default_language_id"
                                            title="default_language_id"
                                            value={fields.default_language_id}
                                            status={language}
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                          />
                                          {errors.default_language_id ? (
                                            <Label title="default_language_id" fieldError={errors.default_language_id} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Region" />

                                          <TextInput id="Region" name="Region" value="Japan" is_read_only={true} />
                                        </div>
                                        {/* <div className="">
                                          <Label title="Date Format" />
                                          <Dropdown
                                            id="date_format"
                                            name="date_format"
                                            title="date_format"
                                            value={fields.date_format}
                                            status={dateFormate}
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                          />
                                          {errors.date_format ? (
                                            <Label title="date_format" fieldError={errors.date_format} />
                                          ) : null}
                                        </div> */}
                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={'/'} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {tab === 3 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Passenger Referral Settings</h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">
                                        From here you can add/edit Passenger Referral settings.
                                      </p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="col-span-2">
                                          <div className="flex items-center">
                                            <Label title="Passenger Free Ride" />
                                            &nbsp;&nbsp;
                                            <Switch
                                              checked={fields.is_passenger_free_ride}
                                              onChange={(e) => onChangeSwitch(e, 'is_passenger_free_ride')}
                                              className={classNames(
                                                fields.is_passenger_free_ride ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                              )}
                                            >
                                              <span className="sr-only">Use setting</span>
                                              <span
                                                aria-hidden="true"
                                                className={classNames(
                                                  fields.is_passenger_free_ride ? 'translate-x-5' : 'translate-x-0',
                                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                )}
                                              />
                                            </Switch>
                                          </div>
                                        </div>

                                        <div className="">
                                          <Label title="Inviter Discount Amount" />
                                          <TextInput
                                            id="inviter_discount_amount"
                                            name="inviter_discount_amount"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.inviter_discount_amount}
                                            is_number={true}
                                            is_read_only={!fields.is_passenger_free_ride}
                                          />
                                          {errors.inviter_discount_amount ? (
                                            <Label title="inviter_discount_amount" fieldError={errors.inviter_discount_amount} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Invitee Discount Amount" />
                                          <TextInput
                                            id="invitee_discount_amount"
                                            name="invitee_discount_amount"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.invitee_discount_amount}
                                            is_number={true}
                                            is_read_only={!fields.is_passenger_free_ride}
                                          />
                                          {errors.invitee_discount_amount ? (
                                            <Label title="invitee_discount_amount" fieldError={errors.invitee_discount_amount} />
                                          ) : null}
                                        </div>
                                        {language.map((single, index) => (
                                          <div className="">
                                            <Label title={`App Caption [${single.title}]`} />
                                            <TextInput
                                              id={`app_caption_${single.code}`}
                                              name={`app_caption_${single.code}`}
                                              handleChange={(field, e) =>
                                                setFields({
                                                  ...fields,
                                                  [field]: e.target.value,
                                                })
                                              }
                                              value={fields[`app_caption_${single.code}`]}
                                              is_number={false}
                                              is_read_only={!fields.is_passenger_free_ride}
                                            />
                                          </div>
                                        ))}

                                        {language.map((single, index) => (
                                          <div className="">
                                            <Label title={`App Share Caption [${single.title}]`} />
                                            <TextInput
                                              id={`app_share_caption_${single.code}`}
                                              name={`app_share_caption_${single.code}`}
                                              handleChange={(field, e) =>
                                                setFields({
                                                  ...fields,
                                                  [field]: e.target.value,
                                                })
                                              }
                                              value={fields[`app_share_caption_${single.code}`]}
                                              is_number={false}
                                              is_read_only={!fields.is_passenger_free_ride}
                                            />
                                          </div>
                                        ))}

                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={'/'} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {tab === 4 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Driver Referral Settings</h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">From here you can add/edit Driver Referral settings.</p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="col-span-2">
                                          <div className="flex items-center">
                                            <Label title="Driver Refer" />
                                            &nbsp;&nbsp;
                                            <Switch
                                              checked={fields.is_driver_refer}
                                              onChange={(e) => onChangeSwitch(e, 'is_driver_refer')}
                                              className={classNames(
                                                fields.is_driver_refer ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                              )}
                                            >
                                              <span className="sr-only">Use setting</span>
                                              <span
                                                aria-hidden="true"
                                                className={classNames(
                                                  fields.is_driver_refer ? 'translate-x-5' : 'translate-x-0',
                                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                )}
                                              />
                                            </Switch>
                                          </div>
                                        </div>

                                        <div className="">
                                          <Label title="Inviter Bonus Amount" />
                                          <TextInput
                                            id="inviter_bonus_amount"
                                            name="inviter_bonus_amount"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.inviter_bonus_amount}
                                            is_number={true}
                                            is_read_only={!fields.is_driver_refer}
                                          />
                                          {errors.inviter_bonus_amount ? (
                                            <Label title="inviter_bonus_amount" fieldError={errors.inviter_bonus_amount} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Invitee Bonus Amount" />
                                          <TextInput
                                            id="invitee_bonus_amount"
                                            name="invitee_bonus_amount"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.invitee_bonus_amount}
                                            is_number={true}
                                            is_read_only={!fields.is_driver_refer}
                                          />
                                          {errors.invitee_bonus_amount ? (
                                            <Label title="invitee_bonus_amount" fieldError={errors.invitee_bonus_amount} />
                                          ) : null}
                                        </div>
                                        <div className="">
                                          <Label title="Number of Rides Completion" />
                                          <TextInput
                                            id="no_of_rides_completion"
                                            name="no_of_rides_completion"
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                            value={fields.no_of_rides_completion}
                                            is_number={true}
                                            is_read_only={!fields.is_driver_refer}
                                          />
                                          {errors.no_of_rides_completion ? (
                                            <Label title="no_of_rides_completion" fieldError={errors.no_of_rides_completion} />
                                          ) : null}
                                        </div>
                                        {language.map((single, index) => (
                                          <div className="">
                                            <Label title={`App Caption [${single.title}]`} />
                                            <TextInput
                                              id={`driver_app_caption_${single.code}`}
                                              name={`driver_app_caption_${single.code}`}
                                              handleChange={(field, e) =>
                                                setFields({
                                                  ...fields,
                                                  [field]: e.target.value,
                                                })
                                              }
                                              value={fields[`driver_app_caption_${single.code}`]}
                                              is_number={false}
                                              is_read_only={!fields.is_driver_refer}
                                            />
                                          </div>
                                        ))}

                                        {language.map((single, index) => (
                                          <div className="">
                                            <Label title={`App Share Caption [${single.title}]`} />
                                            <TextInput
                                              id={`driver_app_share_caption_${single.code}`}
                                              name={`driver_app_share_caption_${single.code}`}
                                              handleChange={(field, e) =>
                                                setFields({
                                                  ...fields,
                                                  [field]: e.target.value,
                                                })
                                              }
                                              value={fields[`driver_app_share_caption_${single.code}`]}
                                              is_number={false}
                                              is_read_only={!fields.is_driver_refer}
                                            />
                                          </div>
                                        ))}

                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={'/'} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {tab === 5 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Trip Settings</h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">From here you can add/edit trip settings.</p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="">
                                          <Label title="Driver Timer Type" />

                                          <Dropdown
                                            id="driver_timer_type"
                                            name="driver_timer_type"
                                            title="driver_timer_type"
                                            value={fields.driver_timer_type}
                                            status={timers}
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                          />
                                          {errors.driver_timer_type ? (
                                            <Label title="driver_timer_type" fieldError={errors.driver_timer_type} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Driver Timer value" />

                                          <input
                                            type="text"
                                            name="driver_timer_value"
                                            id="driver_timer_value"
                                            value={fields.driver_timer_value}
                                            onChange={(e) => handleChange('driver_timer_value', e)}
                                            autoComplete="driver_timer_value"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.driver_timer_value ? (
                                            <Label title="driver_timer_value" fieldError={errors.driver_timer_value} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Schedule Trip Timer Type" />

                                          <Dropdown
                                            id="schedule_trip_time_type"
                                            name="schedule_trip_time_type"
                                            title="schedule_trip_time_type"
                                            value={fields.schedule_trip_time_type}
                                            status={timers}
                                            handleChange={(field, e) =>
                                              setFields({
                                                ...fields,
                                                [field]: e.target.value,
                                              })
                                            }
                                          />
                                          {errors.schedule_trip_time_type ? (
                                            <Label title="schedule_trip_time_type" fieldError={errors.schedule_trip_time_type} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Schedule Trip Time value" />

                                          <input
                                            type="text"
                                            name="schedule_trip_time_value"
                                            id="schedule_trip_time_value"
                                            value={fields.schedule_trip_time_value}
                                            onChange={(e) => handleChange('schedule_trip_time_value', e)}
                                            autoComplete="schedule_trip_time_value"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.schedule_trip_time_value ? (
                                            <Label title="schedule_trip_time_value" fieldError={errors.schedule_trip_time_value} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="New Request Time Out In Seconds (For Driver / Private Ride)" />
                                          <input
                                            type="text"
                                            name="driverSendRequestTime"
                                            id="driverSendRequestTime"
                                            value={fields.driverSendRequestTime}
                                            onChange={(e) => handleChange('driverSendRequestTime', e)}
                                            autoComplete="driverSendRequestTime"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.driverSendRequestTime ? (
                                            <Label title="driverSendRequestTime" fieldError={errors.driverSendRequestTime} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="New Request Time Out In Seconds (For Driver / Share ride)" />
                                          <input
                                            type="text"
                                            name="driverSendRequestTimerShare"
                                            id="driverSendRequestTimerShare"
                                            value={fields.driverSendRequestTimerShare}
                                            onChange={(e) => handleChange('driverSendRequestTimerShare', e)}
                                            autoComplete="driverSendRequestTimerShare"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.driverSendRequestTimerShare ? (
                                            <Label title="driverSendRequestTimerShare" fieldError={errors.driverSendRequestTimerShare} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Trip Radius (Private Ride - In Meter)" />

                                          <input
                                            type="text"
                                            name="radius"
                                            id="radius"
                                            value={fields.radius}
                                            onChange={(e) => handleChange('radius', e)}
                                            autoComplete="radius"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.radius ? <Label title="radius" fieldError={errors.radius} /> : null}
                                        </div>

                                        <div className="">
                                          <Label title="Firebase Location Distance Filter (in meter)" />

                                          <input
                                            type="text"
                                            name="distance_filter"
                                            id="distance_filter"
                                            value={fields.distance_filter}
                                            onChange={(e) => handleChange('distance_filter', e)}
                                            autoComplete="distance_filter"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.distance_filter ? <Label title="distance_filter" fieldError={errors.distance_filter} /> : null}
                                        </div>
                                        {/* <div className="">
                                          <Label title="Firebase Time Interval Normal" />

                                          <input
                                            type="number"
                                            name="firebase_time_interval_normal"
                                            id="firebase_time_interval_normal"
                                            value={
                                              fields.firebase_time_interval_normal
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                "firebase_time_interval_normal",
                                                e
                                              )
                                            }
                                            autoComplete="firebase_time_interval_normal"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.firebase_time_interval_normal ? (
                                            <Label
                                              title="firebase_time_interval_normal"
                                              fieldError={
                                                errors.firebase_time_interval_normal
                                              }
                                            />
                                          ) : null}
                                        </div>
                                        <div className="">
                                          <Label title="Firebase Time Interval in Running Trip" />

                                          <input
                                            type="number"
                                            name="firebase_time_interval_in_trip"
                                            id="firebase_time_interval_in_trip"
                                            value={
                                              fields.firebase_time_interval_in_trip
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                "firebase_time_interval_in_trip",
                                                e
                                              )
                                            }
                                            autoComplete="firebase_time_interval_in_trip"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.firebase_time_interval_in_trip ? (
                                            <Label
                                              title="firebase_time_interval_in_trip"
                                              fieldError={
                                                errors.firebase_time_interval_in_trip
                                              }
                                            />
                                          ) : null}
                                        </div> */}
                                        {/* <div className="">
                                          <Label title="Designated Minimum Fare" />

                                          <input
                                            type="text"
                                            name="designated_min_fare"
                                            id="designated_min_fare"
                                            value={fields.designated_min_fare}
                                            onChange={(e) => handleChange("designated_min_fare", e)}
                                            autoComplete="designated_min_fare"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.designated_min_fare ? (
                                            <Label
                                              title="designated_min_fare"
                                              fieldError={errors.designated_min_fare}
                                            />
                                          ) : null}
                                        </div>
                                        <div className="">
                                          <Label title="Designated Minimum Km" />

                                          <input
                                            type="text"
                                            name="designated_min_km"
                                            id="designated_min_km"
                                            value={fields.designated_min_km}
                                            onChange={(e) => handleChange("designated_min_km", e)}
                                            autoComplete="designated_min_km"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.designated_min_km ? (
                                            <Label title="designated_min_km" fieldError={errors.designated_min_km} />
                                          ) : null}
                                        </div>
                                        <div className="">
                                          <Label title="Designated Per Km Fare" />

                                          <input
                                            type="text"
                                            name="designated_per_km_fare"
                                            id="designated_per_km_fare"
                                            value={fields.designated_per_km_fare}
                                            onChange={(e) => handleChange("designated_per_km_fare", e)}
                                            autoComplete="designated_per_km_fare"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.designated_per_km_fare ? (
                                            <Label
                                              title="designated_per_km_fare"
                                              fieldError={errors.designated_per_km_fare}
                                            />
                                          ) : null}
                                        </div> */}
                                        {/* <div className="">
                                          <Label title="Company Commission (%)" />

                                          <input
                                            type="text"
                                            name="company_commission"
                                            id="company_commission"
                                            value={fields.company_commission}
                                            onChange={(e) => handleChange("company_commission", e)}
                                            autoComplete="company_commission"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.company_commission ? (
                                            <Label title="company_commission" fieldError={errors.company_commission} />
                                          ) : null}
                                        </div> */}

                                        <div className="">
                                          <Label title="Driver Route Near By KM (Share & Driver Route Rides)" />
                                          <input
                                            type="text"
                                            name="driver_route_nearby_km"
                                            id="driver_route_nearby_km"
                                            value={fields.driver_route_nearby_km}
                                            onChange={(e) => handleChange('driver_route_nearby_km', e)}
                                            autoComplete="driver_route_nearby_km"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.driver_route_nearby_km ? (
                                            <Label title="driver_route_nearby_km" fieldError={errors.driver_route_nearby_km} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Company Commission" />
                                          <input
                                            type="number"
                                            name="bookingFees"
                                            id="bookingFees"
                                            value={fields.bookingFees}
                                            onChange={(e) => handleChange('bookingFees', e)}
                                            autoComplete="bookingFees"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.bookingFees ? <Label title="bookingFees" fieldError={errors.bookingFees} /> : null}
                                        </div>

                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={'/'} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {tab === 6 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Other Settings</h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">From here you can add/edit some other settings.</p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="col-span-2">
                                          <div className="flex items-center">
                                            <Label title="OTP Auto Fill" />
                                            &nbsp;&nbsp;
                                            <Switch
                                              checked={fields.is_otp_auto_fill}
                                              onChange={(e) => onChangeSwitch(e, 'is_otp_auto_fill')}
                                              className={classNames(
                                                fields.is_otp_auto_fill ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                              )}
                                            >
                                              <span className="sr-only">Use setting</span>
                                              <span
                                                aria-hidden="true"
                                                className={classNames(
                                                  fields.is_otp_auto_fill ? 'translate-x-5' : 'translate-x-0',
                                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                )}
                                              />
                                            </Switch>
                                          </div>
                                        </div>
                                        <div className="">
                                          <Label title="Allow cancellation time for passenger" />

                                          <input
                                            type="text"
                                            name="passenger_allow_cancellation_time"
                                            id="passenger_allow_cancellation_time"
                                            value={fields.passenger_allow_cancellation_time}
                                            onChange={(e) => handleChange('passenger_allow_cancellation_time', e)}
                                            autoComplete="passenger_allow_cancellation_time"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.passenger_allow_cancellation_time ? (
                                            <Label title="passenger_allow_cancellation_time" fieldError={errors.passenger_allow_cancellation_time} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Cancellation fine for passenger" />

                                          <input
                                            type="text"
                                            name="passenger_cancellation_fine"
                                            id="passenger_cancellation_fine"
                                            value={fields.passenger_cancellation_fine}
                                            onChange={(e) => handleChange('passenger_cancellation_fine', e)}
                                            autoComplete="passenger_cancellation_fine"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.passenger_cancellation_fine ? (
                                            <Label title="passenger_cancellation_fine" fieldError={errors.passenger_cancellation_fine} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Cancellation fine for drivers when wallet is in Minus =" />

                                          <input
                                            type="text"
                                            name="driver_cancellation_fine_for_wallet"
                                            id="driver_cancellation_fine_for_wallet"
                                            value={fields.driver_cancellation_fine_for_wallet}
                                            onChange={(e) => handleChange('driver_cancellation_fine_for_wallet', e)}
                                            autoComplete="driver_cancellation_fine_for_wallet"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.driver_cancellation_fine_for_wallet ? (
                                            <Label
                                              title="driver_cancellation_fine_for_wallet"
                                              fieldError={errors.driver_cancellation_fine_for_wallet}
                                            />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Cancellation fine for drivers" />
                                          <input
                                            type="text"
                                            name="driver_cancellation_fine"
                                            id="driver_cancellation_fine"
                                            value={fields.driver_cancellation_fine}
                                            onChange={(e) => handleChange('driver_cancellation_fine', e)}
                                            autoComplete="driver_cancellation_fine"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.driver_cancellation_fine ? (
                                            <Label title="driver_cancellation_fine" fieldError={errors.driver_cancellation_fine} />
                                          ) : null}
                                        </div>
                                        <div className="">
                                          <Label title="SOS" />
                                          <input
                                            type="text"
                                            name="sos"
                                            id="sos"
                                            value={fields.sos}
                                            onChange={(e) => handleChange('sos', e)}
                                            autoComplete="sos"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.sos ? <Label title="sos" fieldError={errors.sos} /> : null}
                                        </div>
                                        <div className="">
                                          <Label title="Driver Minimum Wallet Balance" />
                                          <input
                                            type="text"
                                            name="driver_min_wallet_balance"
                                            id="driver_min_wallet_balance"
                                            value={fields.driver_min_wallet_balance}
                                            onChange={(e) => handleChange('driver_min_wallet_balance', e)}
                                            autoComplete="driver_min_wallet_balance"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.driver_min_wallet_balance ? (
                                            <Label title="driver_min_wallet_balance" fieldError={errors.driver_min_wallet_balance} />
                                          ) : null}
                                        </div>

                                        <div className="">
                                          <Label title="Schedule Ride Minimum Minute" />
                                          <input
                                            type="text"
                                            name="schedule_start_minutes"
                                            id="schedule_start_minutes"
                                            value={fields.schedule_start_minutes}
                                            onChange={(e) => handleChange('schedule_start_minutes', e)}
                                            autoComplete="schedule_start_minutes"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.schedule_start_minutes ? (
                                            <Label title="schedule_start_minutes" fieldError={errors.schedule_start_minutes} />
                                          ) : null}
                                        </div>
                                        <div className="">
                                          <Label title="Real Time Data Update (meter)" />
                                          <input
                                            type="text"
                                            name="real_time_data_update_meter"
                                            id="real_time_data_update_meter"
                                            value={fields.real_time_data_update_meter}
                                            onChange={(e) => handleChange('real_time_data_update_meter', e)}
                                            autoComplete="real_time_data_update_meter"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.real_time_data_update_meter ? (
                                            <Label title="real_time_data_update_meter" fieldError={errors.real_time_data_update_meter} />
                                          ) : null}
                                        </div>
                                      </div>
                                      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                        <div className="grid xl:grid-cols-3 grid-cols-1 gap-6 bg-white drop-shadow my-5 px-4 py-4">
                                          <div className="xl:col-span-3">
                                            <Label title="Customer Average Rating" />
                                          </div>
                                          <div className="">
                                            <Label title="Standard Badge" />
                                            <input
                                              type="text"
                                              name="standard_badge_value"
                                              id="standard_badge_value"
                                              value={fields.standard_badge_value}
                                              onChange={(e) => handleChange("standard_badge_value", e)}
                                              autoComplete="standard_badge_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.standard_badge_value ? (
                                              <Label
                                                title="standard_badge_value"
                                                fieldError={errors.standard_badge_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Silver Badge" />
                                            <input
                                              type="text"
                                              name="silver_badge_value"
                                              id="silver_badge_value"
                                              value={fields.silver_badge_value}
                                              onChange={(e) => handleChange("silver_badge_value", e)}
                                              autoComplete="silver_badge_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.silver_badge_value ? (
                                              <Label
                                                title="silver_badge_value"
                                                fieldError={errors.silver_badge_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Golden Badge" />
                                            <input
                                              type="text"
                                              name="golden_badge_value"
                                              id="golden_badge_value"
                                              value={fields.golden_badge_value}
                                              onChange={(e) => handleChange("golden_badge_value", e)}
                                              autoComplete="golden_badge_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.golden_badge_value ? (
                                              <Label
                                                title="golden_badge_value"
                                                fieldError={errors.golden_badge_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="xl:col-span-3">
                                            <Label title="Customer Review Count" />
                                          </div>
                                          <div className="">
                                            <Label title="St. Badge Review" />
                                            <input
                                              type="text"
                                              name="standard_badge_review_value"
                                              id="standard_badge_review_value"
                                              value={fields.standard_badge_review_value}
                                              onChange={(e) => handleChange("standard_badge_review_value", e)}
                                              autoComplete="standard_badge_review_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.standard_badge_review_value ? (
                                              <Label
                                                title="standard_badge_review_value"
                                                fieldError={errors.standard_badge_review_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Silver Badge Review" />
                                            <input
                                              type="text"
                                              name="silver_badge_review_value"
                                              id="silver_badge_review_value"
                                              value={fields.silver_badge_review_value}
                                              onChange={(e) => handleChange("silver_badge_review_value", e)}
                                              autoComplete="silver_badge_review_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.silver_badge_review_value ? (
                                              <Label
                                                title="silver_badge_review_value"
                                                fieldError={errors.silver_badge_review_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Golden Badge Review" />
                                            <input
                                              type="text"
                                              name="golden_badge_review_value"
                                              id="golden_badge_review_value"
                                              value={fields.golden_badge_review_value}
                                              onChange={(e) => handleChange("golden_badge_review_value", e)}
                                              autoComplete="golden_badge_review_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.golden_badge_review_value ? (
                                              <Label
                                                title="golden_badge_review_value"
                                                fieldError={errors.golden_badge_review_value}
                                              />
                                            ) : null}
                                          </div>
                                        </div>

                                        <div className="grid xl:grid-cols-3 grid-cols-1 gap-6 bg-white drop-shadow my-5 px-4 py-4">
                                          <div className="xl:col-span-3">
                                            <Label title="Driver Average Rating" />
                                          </div>
                                          <div className="">
                                            <Label title="Standard Badge" />
                                            <input
                                              type="text"
                                              name="driver_standard_badge_value"
                                              id="driver_standard_badge_value"
                                              value={fields.driver_standard_badge_value}
                                              onChange={(e) => handleChange("driver_standard_badge_value", e)}
                                              autoComplete="driver_standard_badge_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.driver_standard_badge_value ? (
                                              <Label
                                                title="driver_standard_badge_value"
                                                fieldError={errors.driver_standard_badge_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Plus Badge" />
                                            <input
                                              type="text"
                                              name="plus_badge_value"
                                              id="plus_badge_value"
                                              value={fields.plus_badge_value}
                                              onChange={(e) => handleChange("plus_badge_value", e)}
                                              autoComplete="plus_badge_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.plus_badge_value ? (
                                              <Label title="plus_badge_value" fieldError={errors.plus_badge_value} />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Premium Badge" />
                                            <input
                                              type="text"
                                              name="premium_badge_value"
                                              id="premium_badge_value"
                                              value={fields.premium_badge_value}
                                              onChange={(e) => handleChange("premium_badge_value", e)}
                                              autoComplete="premium_badge_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.premium_badge_value ? (
                                              <Label
                                                title="premium_badge_value"
                                                fieldError={errors.premium_badge_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="xl:col-span-3">
                                            <Label title="Driver Review Count" />
                                          </div>
                                          <div className="">
                                            <Label title="St. Badge Review" />
                                            <input
                                              type="text"
                                              name="driver_standard_badge_review_value"
                                              id="driver_standard_badge_review_value"
                                              value={fields.driver_standard_badge_review_value}
                                              onChange={(e) => handleChange("driver_standard_badge_review_value", e)}
                                              autoComplete="driver_standard_badge_review_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.driver_standard_badge_review_value ? (
                                              <Label
                                                title="driver_standard_badge_review_value"
                                                fieldError={errors.driver_standard_badge_review_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Plus Badge Review" />
                                            <input
                                              type="text"
                                              name="plus_badge_review_value"
                                              id="plus_badge_review_value"
                                              value={fields.plus_badge_review_value}
                                              onChange={(e) => handleChange("plus_badge_review_value", e)}
                                              autoComplete="plus_badge_review_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.plus_badge_review_value ? (
                                              <Label
                                                title="plus_badge_review_value"
                                                fieldError={errors.plus_badge_review_value}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="">
                                            <Label title="Premium Badge Review" />
                                            <input
                                              type="text"
                                              name="premium_badge_review_value"
                                              id="premium_badge_review_value"
                                              value={fields.premium_badge_review_value}
                                              onChange={(e) => handleChange("premium_badge_review_value", e)}
                                              autoComplete="premium_badge_review_value"
                                              min="1"
                                              max="5"
                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                            />
                                            {errors.premium_badge_review_value ? (
                                              <Label
                                                title="premium_badge_review_value"
                                                fieldError={errors.premium_badge_review_value}
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                      </div> */}

                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={'/'} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {/* {tab === 7 ? (
                                  <div className="px-6 sm:py-10 col-span-10">
                                    <div className="pb-7">
                                      <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">
                                        Point Settings
                                      </h2>
                                      <p className="mt-1 text-base text-gray-500 text_theme">
                                        This information is show point settings.
                                      </p>
                                    </div>
                                    <form action="#" method="POST">
                                      <div className="w-3/5 grid grid-cols-3 gap-4">
                                        <div className="">
                                          <Label title="Points As Per Single Ride" />
                                          <input
                                            type="text"
                                            name="single_ride_points"
                                            id="single_ride_points"
                                            value={fields.single_ride_points}
                                            onChange={(e) => handleChange("single_ride_points", e)}
                                            autoComplete="single_ride_points"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.single_ride_points ? (
                                            <Label title="single_ride_points" fieldError={errors.single_ride_points} />
                                          ) : null}
                                        </div>
                                        <div className="">
                                          <Label title="No. of Rides to be finished" />
                                          <input
                                            type="text"
                                            name="rides_to_be_finished"
                                            id="rides_to_be_finished"
                                            value={fields.rides_to_be_finished}
                                            onChange={(e) => handleChange("rides_to_be_finished", e)}
                                            autoComplete="rides_to_be_finished"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                          />
                                          {errors.rides_to_be_finished ? (
                                            <Label
                                              title="rides_to_be_finished"
                                              fieldError={errors.rides_to_be_finished}
                                            />
                                          ) : null}
                                        </div>
                                        <div>
                                          <Label title="Total Points" />
                                          <input
                                            type="text"
                                            name="single_ride_points"
                                            id="single_ride_points"
                                            disabled={true}
                                            value={
                                              fields.single_ride_points > 0 && fields.rides_to_be_finished > 0
                                                ? fields.single_ride_points * fields.rides_to_be_finished
                                                : 0
                                            }
                                            autoComplete="single_ride_points"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme bg-gray-200"
                                          />
                                        </div>
                                        {language.map((single, index) => (
                                          <div className="">
                                            <Label title={`Points Description [${single.title}]`} />
                                            <TextInput
                                              id={`pointsDescription_${single.code}`}
                                              name={`pointsDescription_${single.code}`}
                                              handleChange={(field, e) =>
                                                setFields({
                                                  ...fields,
                                                  [field]: e.target.value,
                                                })
                                              }
                                              value={fields[`pointsDescription_${single.code}`]}
                                            />
                                          </div>
                                        ))}
                                      </div>

                                      <div className="grid grid-cols-2 gap-6">
                                        <div className="pt-5 col-span-2 pr-6">
                                          <div className="flex justify-end">
                                            <BtnSaveRecord title="Save" path={"/"} handleSubmit={handleSubmit} />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>{' '}
        </>
      ) : (
        <>
          {' '}
          <Loader />{' '}
        </>
      )}
    </>
  );
}

export default View;
