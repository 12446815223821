import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Route For Guest User
function GuestRoute({ component: Component, ...rest }) {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="colored"
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Route {...rest} render={(props) => <Component {...props} />} />
    </>
  );
}
GuestRoute.propTypes = {
  component: PropTypes.string,
};
GuestRoute.defaultProps = {
  component: '',
};

export default GuestRoute;
