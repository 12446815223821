import { XMarkIcon, CheckIcon, TrashIcon, ArchiveBoxIcon, ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { PlusIcon as PlusIconSolid } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import React from 'react';
import { findWhere } from 'underscore';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Buttons
function ActionButton({
  btnType,
  key,
  btnPath,
  btnTitle = '',
  handleAction,
  csvData = [],
  csvTitle = '',
  moduleName = '',
  permission_type = '',
  authorisation = [],
}) {
  let btnTypeStr = '';
  switch (btnType) {
    case 'addNew':
      btnTypeStr = <PlusIconSolid className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'makeActive':
      btnTypeStr = <CheckIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'makeInactive':
      btnTypeStr = <XMarkIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'makeApproved':
      btnTypeStr = <CheckIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'makeUnapproved':
      btnTypeStr = <XMarkIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'archive':
      btnTypeStr = <ArchiveBoxIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'trashed':
      btnTypeStr = <ArchiveBoxIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'delete':
      btnTypeStr = <TrashIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'exportCSV':
      btnTypeStr = <ArrowDownTrayIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    case 'importCSV':
      btnTypeStr = <ArrowUpTrayIcon className="-ml-0.5 mr-2 h-5 w-5 text-skin-secondary" aria-hidden="true" />;
      break;
    default:
      btnTypeStr = '';
  }

  return (
    <Menu.Item key={key}>
      {({ active }) =>
        btnType === 'addNew' ? (
          findWhere(authorisation, { code: moduleName })?.data?.[permission_type] ? (
            <Link to={btnPath} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-base flex')}>
              {btnTypeStr} {btnTitle}
            </Link>
          ) : (
            <> </>
          )
        ) : btnType === 'exportCSV' ? (
          <span className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-base flex cursor-pointer')}>
            <CSVLink
              style={{ display: 'flex' }}
              data={csvData}
              filename={
                csvTitle
                  ? `${csvTitle}${moment().format('MM-DD-YYYY, h-mm-ss a')}.csv`
                  : 'Language_Lable_' + moment().format('MM-DD-YYYY, h-mm-ss a') + '.csv'
              }
            >
              {btnTypeStr} {btnTitle}
            </CSVLink>
          </span>
        ) : findWhere(authorisation, { code: moduleName })?.data?.[permission_type] ? (
          <span
            // to={btnPath}
            onClick={() => handleAction(btnType)}
            className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-base flex cursor-pointer')}
          >
            {btnTypeStr} {btnTitle}
          </span>
        ) : (
          <></>
        )
      }
    </Menu.Item>
  );
}

ActionButton.propTypes = {
  btnPath: PropTypes.string,
  key: PropTypes.number,
  btnTitle: PropTypes.string,
  btnType: PropTypes.string,
};

ActionButton.defaultProps = {
  btnPath: '',
  key: 0,
  btnTitle: '',
  btnType: '',
};

export default ActionButton;
