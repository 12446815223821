import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

// For routing
function Redirect({ path, title }) {
  const history = useHistory();
  return (
    <button
      type="button"
      className="btn-link py-2 px-4 border border-gray-300 rounded-md shadow-sm text-[16px] font-medium shadow-sm bg-skin-white text-skin-black rounded-md"
      onClick={() => history.push(path)}
    >
      {title}
    </button>
  );
}

Redirect.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
};
Redirect.defaultProps = {
  path: '',
  title: '',
};

export default Redirect;
