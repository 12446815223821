import { HomeIcon, BriefcaseIcon, MapPinIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import configuration from '../../../config';
import { toast } from 'react-toastify';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let Id = '';
// List Settings
function Summary({ user_id }) {
  const [actions, setActions] = useState([]);
  useEffect(() => {
    // var url = window.location.href;
    // Id = url.substring(url.lastIndexOf("/") + 1);
    Id = user_id;
    configuration
      .getAPI({ url: 'app-user/favourite-address', params: { user_id: Id } })
      .then((data) => {
        if (data.payload) {
          setActions(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Favourite Address
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user favourite address.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            {actions.length > 0 ? (
              <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                {actions.map((action, actionIdx) => (
                  <div
                    key={action.title}
                    className={classNames(
                      actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                      actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                      actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                      actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                      'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                    )}
                  >
                    <div className="">
                      {action.title.toLowerCase() === 'home' ? (
                        <HomeIcon className="h-5 w-5 " aria-hidden="true" />
                      ) : action.title.toLowerCase() === 'work' ? (
                        <BriefcaseIcon className="h-5 w-5 " aria-hidden="true" />
                      ) : (
                        <MapPinIcon className="h-5 w-5 " aria-hidden="true" />
                      )}
                      <h3 className="text-lg font-bold capitalize">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">{action.address}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-1 sm:gap-px">
                This user has no favourite address.
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
