import PropTypes from 'prop-types';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// For Input Element
function TextInput({ id, name, handleChange, value, disablePast, disableFuture }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        className="shadow-sm focus:ring-indigo-500 py-3 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
        name={name}
        id={id}
        value={value}
        format="MM/dd/yyyy"
        onChange={(e) => handleChange(name, e)}
        showTodayButton
        disablePast={disablePast}
        disableFuture={disableFuture}
      />
    </LocalizationProvider>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

TextInput.defaultProps = {
  id: '',
  name: '',
};

export default TextInput;
