import BtnRedirect from './Button/BtnRedirect';
import BtnSaveRecord from './Button/BtnSaveRecord';
import PropTypes from 'prop-types';
import React from 'react';

// For List Button Section`
function ButtonSection({ path, handleSubmit }) {
  return (
    <div className="pt-5 border_color_theme">
      <div className="flex justify-end">
        <BtnRedirect title="Cancel" path={path} />
        <BtnSaveRecord title="Submit" path={path} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}

ButtonSection.propTypes = {
  path: PropTypes.string,
};

ButtonSection.defaultProps = {
  path: '',
};

export default ButtonSection;
