export default {
  countries: [
    {
      value: '+1',
      title: 'United States',
    },
    {
      value: '+7 840',
      title: 'Abkhazia',
    },
    {
      value: '+93',
      title: 'Afghanistan',
    },
    {
      value: '+355',
      title: 'Albania',
    },
    {
      value: '+213',
      title: 'Algeria',
    },
    {
      value: '+1 684',
      title: 'American Samoa',
    },
    {
      value: '+376',
      title: 'Andorra',
    },
    {
      value: '+244',
      title: 'Angola',
    },
    {
      value: '+1 264',
      title: 'Anguilla',
    },
    {
      value: '+1 268',
      title: 'Antigua and Barbuda',
    },
    {
      value: '+54',
      title: 'Argentina',
    },
    {
      value: '+374',
      title: 'Armenia',
    },
    {
      value: '+297',
      title: 'Aruba',
    },
    {
      value: '+247',
      title: 'Ascension',
    },
    {
      value: '+61',
      title: 'Australia',
    },
    {
      value: '+672',
      title: 'Australian External Territories',
    },
    {
      value: '+43',
      title: 'Austria',
    },
    {
      value: '+994',
      title: 'Azerbaijan',
    },
    {
      value: '+1 242',
      title: 'Bahamas',
    },
    {
      value: '+973',
      title: 'Bahrain',
    },
    {
      value: '+880',
      title: 'Bangladesh',
    },
    {
      value: '+1 246',
      title: 'Barbados',
    },
    {
      value: '+1 268',
      title: 'Barbuda',
    },
    {
      value: '+375',
      title: 'Belarus',
    },
    {
      value: '+32',
      title: 'Belgium',
    },
    {
      value: '+501',
      title: 'Belize',
    },
    {
      value: '+229',
      title: 'Benin',
    },
    {
      value: '+1 441',
      title: 'Bermuda',
    },
    {
      value: '+975',
      title: 'Bhutan',
    },
    {
      value: '+591',
      title: 'Bolivia',
    },
    {
      value: '+387',
      title: 'Bosnia and Herzegovina',
    },
    {
      value: '+267',
      title: 'Botswana',
    },
    {
      value: '+55',
      title: 'Brazil',
    },
    {
      value: '+246',
      title: 'British Indian Ocean Territory',
    },
    {
      value: '+1 284',
      title: 'British Virgin Islands',
    },
    {
      value: '+673',
      title: 'Brunei',
    },
    {
      value: '+359',
      title: 'Bulgaria',
    },
    {
      value: '+226',
      title: 'Burkina Faso',
    },
    {
      value: '+257',
      title: 'Burundi',
    },
    {
      value: '+855',
      title: 'Cambodia',
    },
    {
      value: '+237',
      title: 'Cameroon',
    },
    {
      value: '+1',
      title: 'Canada',
    },
    {
      value: '+238',
      title: 'Cape Verde',
    },
    {
      value: '+ 345',
      title: 'Cayman Islands',
    },
    {
      value: '+236',
      title: 'Central African Republic',
    },
    {
      value: '+235',
      title: 'Chad',
    },
    {
      value: '+56',
      title: 'Chile',
    },
    {
      value: '+86',
      title: 'China',
    },
    {
      value: '+61',
      title: 'Christmas Island',
    },
    {
      value: '+61',
      title: 'Cocos-Keeling Islands',
    },
    {
      value: '+57',
      title: 'Colombia',
    },
    {
      value: '+269',
      title: 'Comoros',
    },
    {
      value: '+242',
      title: 'Congo',
    },
    {
      value: '+243',
      title: 'Congo, Dem. Rep. of (Zaire)',
    },
    {
      value: '+682',
      title: 'Cook Islands',
    },
    {
      value: '+506',
      title: 'Costa Rica',
    },
    {
      value: '+385',
      title: 'Croatia',
    },
    {
      value: '+53',
      title: 'Cuba',
    },
    {
      value: '+599',
      title: 'Curacao',
    },
    {
      value: '+537',
      title: 'Cyprus',
    },
    {
      value: '+420',
      title: 'Czech Republic',
    },
    {
      value: '+45',
      title: 'Denmark',
    },
    {
      value: '+246',
      title: 'Diego Garcia',
    },
    {
      value: '+253',
      title: 'Djibouti',
    },
    {
      value: '+1 767',
      title: 'Dominica',
    },
    {
      value: '+1 809',
      title: 'Dominican Republic',
    },
    {
      value: '+670',
      title: 'East Timor',
    },
    {
      value: '+56',
      title: 'Easter Island',
    },
    {
      value: '+593',
      title: 'Ecuador',
    },
    {
      value: '+20',
      title: 'Egypt',
    },
    {
      value: '+503',
      title: 'El Salvador',
    },
    {
      value: '+240',
      title: 'Equatorial Guinea',
    },
    {
      value: '+291',
      title: 'Eritrea',
    },
    {
      value: '+372',
      title: 'Estonia',
    },
    {
      value: '+251',
      title: 'Ethiopia',
    },
    {
      value: '+500',
      title: 'Falkland Islands',
    },
    {
      value: '+298',
      title: 'Faroe Islands',
    },
    {
      value: '+679',
      title: 'Fiji',
    },
    {
      value: '+358',
      title: 'Finland',
    },
    {
      value: '+33',
      title: 'France',
    },
    {
      value: '+596',
      title: 'French Antilles',
    },
    {
      value: '+594',
      title: 'French Guiana',
    },
    {
      value: '+689',
      title: 'French Polynesia',
    },
    {
      value: '+241',
      title: 'Gabon',
    },
    {
      value: '+220',
      title: 'Gambia',
    },
    {
      value: '+995',
      title: 'Georgia',
    },
    {
      value: '+49',
      title: 'Germany',
    },
    {
      value: '+233',
      title: 'Ghana',
    },
    {
      value: '+350',
      title: 'Gibraltar',
    },
    {
      value: '+30',
      title: 'Greece',
    },
    {
      value: '+299',
      title: 'Greenland',
    },
    {
      value: '+1 473',
      title: 'Grenada',
    },
    {
      value: '+590',
      title: 'Guadeloupe',
    },
    {
      value: '+1 671',
      title: 'Guam',
    },
    {
      value: '+502',
      title: 'Guatemala',
    },
    {
      value: '+224',
      title: 'Guinea',
    },
    {
      value: '+245',
      title: 'Guinea-Bissau',
    },
    {
      value: '+595',
      title: 'Guyana',
    },
    {
      value: '+509',
      title: 'Haiti',
    },
    {
      value: '+504',
      title: 'Honduras',
    },
    {
      value: '+852',
      title: 'Hong Kong SAR China',
    },
    {
      value: '+36',
      title: 'Hungary',
    },
    {
      value: '+354',
      title: 'Iceland',
    },
    {
      value: '+91',
      title: 'India',
    },
    {
      value: '+62',
      title: 'Indonesia',
    },
    {
      value: '+98',
      title: 'Iran',
    },
    {
      value: '+964',
      title: 'Iraq',
    },
    {
      value: '+353',
      title: 'Ireland',
    },
    {
      value: '+972',
      title: 'Israel',
    },
    {
      value: '+39',
      title: 'Italy',
    },
    {
      value: '+225',
      title: 'Ivory Coast',
    },
    {
      value: '+1 876',
      title: 'Jamaica',
    },
    {
      value: '+81',
      title: 'Japan',
    },
    {
      value: '+962',
      title: 'Jordan',
    },
    {
      value: '+7 7',
      title: 'Kazakhstan',
    },
    {
      value: '+254',
      title: 'Kenya',
    },
    {
      value: '+686',
      title: 'Kiribati',
    },
    {
      value: '+965',
      title: 'Kuwait',
    },
    {
      value: '+996',
      title: 'Kyrgyzstan',
    },
    {
      value: '+856',
      title: 'Laos',
    },
    {
      value: '+371',
      title: 'Latvia',
    },
    {
      value: '+961',
      title: 'Lebanon',
    },
    {
      value: '+266',
      title: 'Lesotho',
    },
    {
      value: '+231',
      title: 'Liberia',
    },
    {
      value: '+218',
      title: 'Libya',
    },
    {
      value: '+423',
      title: 'Liechtenstein',
    },
    {
      value: '+370',
      title: 'Lithuania',
    },
    {
      value: '+352',
      title: 'Luxembourg',
    },
    {
      value: '+853',
      title: 'Macau SAR China',
    },
    {
      value: '+389',
      title: 'Macedonia',
    },
    {
      value: '+261',
      title: 'Madagascar',
    },
    {
      value: '+265',
      title: 'Malawi',
    },
    {
      value: '+60',
      title: 'Malaysia',
    },
    {
      value: '+960',
      title: 'Maldives',
    },
    {
      value: '+223',
      title: 'Mali',
    },
    {
      value: '+356',
      title: 'Malta',
    },
    {
      value: '+692',
      title: 'Marshall Islands',
    },
    {
      value: '+596',
      title: 'Martinique',
    },
    {
      value: '+222',
      title: 'Mauritania',
    },
    {
      value: '+230',
      title: 'Mauritius',
    },
    {
      value: '+262',
      title: 'Mayotte',
    },
    {
      value: '+52',
      title: 'Mexico',
    },
    {
      value: '+691',
      title: 'Micronesia',
    },
    {
      value: '+1 808',
      title: 'Midway Island',
    },
    {
      value: '+373',
      title: 'Moldova',
    },
    {
      value: '+377',
      title: 'Monaco',
    },
    {
      value: '+976',
      title: 'Mongolia',
    },
    {
      value: '+382',
      title: 'Montenegro',
    },
    {
      value: '+1664',
      title: 'Montserrat',
    },
    {
      value: '+212',
      title: 'Morocco',
    },
    {
      value: '+95',
      title: 'Myanmar',
    },
    {
      value: '+264',
      title: 'Namibia',
    },
    {
      value: '+674',
      title: 'Nauru',
    },
    {
      value: '+977',
      title: 'Nepal',
    },
    {
      value: '+31',
      title: 'Netherlands',
    },
    {
      value: '+599',
      title: 'Netherlands Antilles',
    },
    {
      value: '+1 869',
      title: 'Nevis',
    },
    {
      value: '+687',
      title: 'New Caledonia',
    },
    {
      value: '+64',
      title: 'New Zealand',
    },
    {
      value: '+505',
      title: 'Nicaragua',
    },
    {
      value: '+227',
      title: 'Niger',
    },
    {
      value: '+234',
      title: 'Nigeria',
    },
    {
      value: '+683',
      title: 'Niue',
    },
    {
      value: '+672',
      title: 'Norfolk Island',
    },
    {
      value: '+850',
      title: 'North Korea',
    },
    {
      value: '+1 670',
      title: 'Northern Mariana Islands',
    },
    {
      value: '+47',
      title: 'Norway',
    },
    {
      value: '+968',
      title: 'Oman',
    },
    {
      value: '+92',
      title: 'Pakistan',
    },
    {
      value: '+680',
      title: 'Palau',
    },
    {
      value: '+970',
      title: 'Palestinian Territory',
    },
    {
      value: '+507',
      title: 'Panama',
    },
    {
      value: '+675',
      title: 'Papua New Guinea',
    },
    {
      value: '+595',
      title: 'Paraguay',
    },
    {
      value: '+51',
      title: 'Peru',
    },
    {
      value: '+63',
      title: 'Philippines',
    },
    {
      value: '+48',
      title: 'Poland',
    },
    {
      value: '+351',
      title: 'Portugal',
    },
    {
      value: '+1 787',
      title: 'Puerto Rico',
    },
    {
      value: '+974',
      title: 'Qatar',
    },
    {
      value: '+262',
      title: 'Reunion',
    },
    {
      value: '+40',
      title: 'Romania',
    },
    {
      value: '+7',
      title: 'Russia',
    },
    {
      value: '+250',
      title: 'Rwanda',
    },
    {
      value: '+685',
      title: 'Samoa',
    },
    {
      value: '+378',
      title: 'San Marino',
    },
    {
      value: '+966',
      title: 'Saudi Arabia',
    },
    {
      value: '+221',
      title: 'Senegal',
    },
    {
      value: '+381',
      title: 'Serbia',
    },
    {
      value: '+248',
      title: 'Seychelles',
    },
    {
      value: '+232',
      title: 'Sierra Leone',
    },
    {
      value: '+65',
      title: 'Singapore',
    },
    {
      value: '+421',
      title: 'Slovakia',
    },
    {
      value: '+386',
      title: 'Slovenia',
    },
    {
      value: '+677',
      title: 'Solomon Islands',
    },
    {
      value: '+27',
      title: 'South Africa',
    },
    {
      value: '+500',
      title: 'South Georgia and the South Sandwich Islands',
    },
    {
      value: '+82',
      title: 'South Korea',
    },
    {
      value: '+34',
      title: 'Spain',
    },
    {
      value: '+94',
      title: 'Sri Lanka',
    },
    {
      value: '+249',
      title: 'Sudan',
    },
    {
      value: '+597',
      title: 'title',
    },
    {
      value: '+268',
      title: 'Swaziland',
    },
    {
      value: '+46',
      title: 'Sweden',
    },
    {
      value: '+41',
      title: 'Switzerland',
    },
    {
      value: '+963',
      title: 'Syria',
    },
    {
      value: '+886',
      title: 'Taiwan',
    },
    {
      value: '+992',
      title: 'Tajikistan',
    },
    {
      value: '+255',
      title: 'Tanzania',
    },
    {
      value: '+66',
      title: 'Thailand',
    },
    {
      value: '+670',
      title: 'Timor Leste',
    },
    {
      value: '+228',
      title: 'Togo',
    },
    {
      value: '+690',
      title: 'Tokelau',
    },
    {
      value: '+676',
      title: 'Tonga',
    },
    {
      value: '+1 868',
      title: 'Trinidad and Tobago',
    },
    {
      value: '+216',
      title: 'Tunisia',
    },
    {
      value: '+90',
      title: 'Turkey',
    },
    {
      value: '+993',
      title: 'Turkmenistan',
    },
    {
      value: '+1 649',
      title: 'Turks and Caicos Islands',
    },
    {
      value: '+688',
      title: 'Tuvalu',
    },
    {
      value: '+1 340',
      title: 'U.S. Virgin Islands',
    },
    {
      value: '+256',
      title: 'Uganda',
    },
    {
      value: '+380',
      title: 'Ukraine',
    },
    {
      value: '+971',
      title: 'United Arab Emirates',
    },
    {
      value: '+44',
      title: 'United Kingdom',
    },
    {
      value: '+598',
      title: 'Uruguay',
    },
    {
      value: '+998',
      title: 'Uzbekistan',
    },
    {
      value: '+678',
      title: 'Vanuatu',
    },
    {
      value: '+58',
      title: 'Venezuela',
    },
    {
      value: '+84',
      title: 'Vietnam',
    },
    {
      value: '+1 808',
      title: 'Wake Island',
    },
    {
      value: '+681',
      title: 'Wallis and Futuna',
    },
    {
      value: '+967',
      title: 'Yemen',
    },
    {
      value: '+260',
      title: 'Zambia',
    },
    {
      value: '+255',
      title: 'Zanzibar',
    },
    {
      value: '+263',
      title: 'Zimbabwe',
    },
  ],
};
