import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import labels from '../../constants/Currency';
import Label from '../../components/Form/Label';
import TextInput from '../../components/Form/TextInput';
import ButtonSection from '../../components/Form/ButtonSection';
import Dropdown from '../../components/Form/Dropdown';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from '../../components/Loader/Loader';

export default function Edit({ history }) {
  const options = [
    { id: 1, title: 'Active', value: 'active' },
    { id: 2, title: 'Inactive', value: 'inactive' },
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [authorisation, setAuthorisation] = useState();
  useEffect(() => {
    let url = window.location.href;
    let ID = url.substring(url.lastIndexOf('/') + 1);
    configuration
      .getAPI({ url: 'currency/list', params: { ID } })
      .then((data) => {
        // console.log(data)
        if (data.payload) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        // console.log(error)
        return toast.error(error.message);
      });
  }, []);
  function validation() {
    let flag = true;
    let error = {};
    if (!fields.title) {
      error['title'] = 'Please enter valid title';
      flag = false;
    }
    if (!fields.code) {
      error['code'] = 'Please enter valid code';
      flag = false;
    }

    if (!fields.symbol) {
      error['symbol'] = 'Please enter valid symbol';
      flag = false;
    }
    if (!fields.status) {
      error['status'] = 'Please select status';
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      configuration
        .allAPI({ url: 'currency/update', method: 'PATCH', params: fields })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            history.push('/currency');
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error('Something went wrong');
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration
        .getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } })
        .then((data) => {
          if (data.payload) {
            setAuthorisation(data.payload);
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }, []);
  useEffect(() => {
    const whereFind = findWhere(authorisation, { code: 'CURRENCY' });
    if (whereFind?.data?.is_edit === false) {
      setIsValid(!isValid);
      history.push('/dashboard');
    }
    if (whereFind?.data?.is_edit === true) {
      setIsValid(!isValid);
    }
  }, [authorisation]);

  return (
    <>
      <Breadcrumb module={labels.editCurrency} />
      <div className="main dark:bg-black bg-white mb-5">
        <div className="dark:bg-black bg-white">
          <div className="py-5 px-5 flex items-center justify-between">
            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.editCurrency}</h1>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full px-4">
            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
              <form className="space-y-6" action="#" method="POST">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div>
                    <Label title="Title" />
                    <TextInput
                      id="title"
                      name="title"
                      handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                      value={fields.title}
                    />
                    {errors.title ? <Label title="title" fieldError={errors.title} /> : null}
                  </div>
                  <div>
                    <Label title="Code" />
                    <TextInput
                      id="code"
                      name="code"
                      handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                      value={fields.code}
                    />
                    {errors.code ? <Label title="code" fieldError={errors.code} /> : null}
                  </div>
                  <div>
                    <Label title="Value" />
                    <TextInput
                      id="symbol"
                      name="symbol"
                      handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                      value={fields.symbol}
                    />
                    {errors.symbol ? <Label title="symbol" fieldError={errors.symbol} /> : null}
                  </div>
                  <div>
                    <Label title="Status" />
                    <Dropdown
                      id="status"
                      name="status"
                      title="status"
                      value={fields.status}
                      status={options}
                      handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                    />
                    {errors.status ? <Label title="status" fieldError={errors.status} /> : null}
                  </div>
                </div>
                <ButtonSection path={'/currency'} handleSubmit={handleSubmit} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
