import React, { useState, useEffect } from 'react';
import configuration from '../../../config';
import DataTable from '../../../components/Table/MDataTable';
import { toast } from 'react-toastify';
import { without } from 'underscore';

let Id = '';
// List Settings
function Summary({ user_id, user_type }) {
  // const [trips, setTrips] = useState([]);
  const columns = [
    { title: 'ID', field: '_id' },
    user_type === 'driver'
      ? {
          title: 'Name',
          field: 'customer',
        }
      : null,
    user_type === 'passenger'
      ? {
          title: 'Name',
          field: 'driver',
        }
      : null,

    {
      title: 'Service',
      field: 'service',
    },
    { title: 'Trip Date', field: 'booking_date' },
    { title: 'Vehicle Type', field: 'rideOption' },
    {
      title: 'Total',
      field: 'totalFare',
      render: (rowData) => (
        <span>
          {rowData.currency} {Number(rowData.totalFare).toFixed(2)}
        </span>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => (
        <span
          className={
            rowData.status === 'completed'
              ? 'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 capitalize'
              : 'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 capitalize'
          }
        >
          {' '}
          {rowData.status}
        </span>
      ),
    },
  ];
  const [data, setData] = useState([]);
  const onSelectionChange = (e) => {
    // let idArr = pluck(e, 'user_id');
    // setIds(idArr)
  };
  useEffect(() => {
    Id = user_id;
    configuration
      .getAPI({ url: 'ride/list', params: { user_id, user_type } })
      .then((data) => {
        if (data.payload) {
          setData(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Trips
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user trips.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            {' '}
            <div className="datatable">
              <DataTable columns={without(columns, null)} data={data} disabledCheckbox={true} onSelectionChange={onSelectionChange} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
