import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import labels from '../../constants/RideOption';
import Label from '../../components/Form/Label';
import TextInput from '../../components/Form/TextInput';
import FileInput from '../../components/Form/FileInput';
import ButtonSection from '../../components/Form/ButtonSection';
import Dropdown from '../../components/Form/Dropdown';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from '../../components/Loader/Loader';

export default function Edit({ history }) {
  const options = [
    { id: 1, title: 'Active', value: 'active' },
    { id: 2, title: 'Inactive', value: 'inactive' },
  ];
  const [languages, setLanguages] = useState([]);
  const [icon, setIcon] = useState(null);
  const [fields, setFields] = useState({});
  const [priceFields, setPriceFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [authorisation, setAuthorisation] = useState();

  useEffect(() => {
    let url = window.location.href;
    let ID = url.substring(url.lastIndexOf('/') + 1);
    configuration
      .getAPI({ url: 'language/list', params: { status: 'active' } })
      .then((data1) => {
        // console.log(data)
        if (data1.payload) {
          setLanguages(data1.payload);
          configuration
            .getAPI({ url: 'ride-option/list', params: { ID } })
            .then((data) => {
              // console.log(data)
              if (data.payload) {
                let titleObj = {};
                each(data1.payload, (lang, ind) => {
                  titleObj['title_' + lang.code] = data.payload.title[lang.code] ? data.payload.title[lang.code] : '';
                });
                delete data.payload.title;
                setFields({ ...titleObj, ...data.payload });
                setPriceFields(data.payload.fare ? data.payload.fare : {});
              }
            })
            .catch((error) => {
              // console.log(error)
              return toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        // console.log(error)
        return toast.error(error.message);
      });
  }, []);
  function validation() {
    let flag = true;
    let error = {};
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = 'Please enter title in ' + lang.title;
        flag = false;
      }
    });
    if (!fields.seating_capacity) {
      error['seating_capacity'] = 'Please enter Seating Capacity';
      flag = false;
    }
    if (!fields.status) {
      error['status'] = 'Please select status';
      flag = false;
    }
    if (!fields.order_no) {
      error['order_no'] = 'Please enter order no';
      flag = false;
    }
    if (!priceFields.base_fare) {
      error['base_fare'] = 'Please enter base fare';
      flag = false;
    }
    if (!priceFields.per_km_fare) {
      error['per_km_fare'] = 'Please enter per km fare';
      flag = false;
    }
    if (!priceFields.per_min_fare) {
      error['per_min_fare'] = 'Please enter per minute fare';
      flag = false;
    }
    if (!priceFields.min_base_km) {
      error['min_base_km'] = 'Please enter minimum base km';
      flag = false;
    }
    if (!priceFields.min_base_fare) {
      error['min_base_fare'] = 'Please enter minimum base fare';
      flag = false;
    }
    if (!priceFields.min_base_fare_for_scheduled_rides) {
      error['min_base_fare_for_scheduled_rides'] = 'Please enter schedule ride base fare';
      flag = false;
    }
    if (!priceFields.wait_fees) {
      error['wait_fees'] = 'Please enter wait fees';
      flag = false;
    }
    if (!priceFields.free_minutes_before_wait_fees_apply) {
      error['free_minutes_before_wait_fees_apply'] = 'Please enter free wait minutes';
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      let titleObj = {};
      each(languages, (lang, ind) => {
        titleObj[lang.code] = fields['title_' + lang.code] ? fields['title_' + lang.code] : '';
        delete fields['title_' + lang.code];
      });
      fields.title = titleObj;
      fields.fare = priceFields;
      const formData = new FormData();
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      let BaseFunction = 'allAPI';
      if (icon) {
        BaseFunction = 'postFormDataAPI';
        formData.append('icon', icon);
      }
      configuration[BaseFunction]({ url: 'ride-option/update', method: 'POST', params: icon ? formData : fields })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            history.push('/ride-option');
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error('Something went wrong');
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };
  function cancleImage(field, e) {
    setIcon(null);
    setFields({ ...fields, [field]: '' });
  }

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration
        .getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } })
        .then((data) => {
          if (data.payload) {
            setAuthorisation(data.payload);
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }, []);
  useEffect(() => {
    const whereFind = findWhere(authorisation, { code: 'VEHICLE_TYPE' });
    if (whereFind?.data?.is_edit === false) {
      setIsValid(!isValid);
      history.push('/dashboard');
    }
    if (whereFind?.data?.is_edit === true) {
      setIsValid(!isValid);
    }
  }, [authorisation]);

  return (
    <>
      {isValid ? (
        <>
          {' '}
          <Breadcrumb module={labels.editRideOption} />
          <div className="main dark:bg-black bg-white mb-5">
            <div className="dark:bg-black bg-white">
              <div className="py-5 px-5 flex items-center justify-between">
                <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.editRideOption}</h1>
              </div>

              <div className="mt-5 sm:mx-auto sm:w-full px-4">
                <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                  <form className="space-y-6" action="#" method="POST">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                      {languages.map((single) => {
                        return (
                          <div>
                            <Label title={`Title [${single.title}]`} />
                            <TextInput
                              id={`title_${single.code}`}
                              name={`title_${single.code}`}
                              handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                              value={fields[`title_${single.code}`]}
                            />
                            {errors[`title_${single.code}`] ? (
                              <Label title={`title_${single.code}`} fieldError={errors[`title_${single.code}`]} />
                            ) : null}
                          </div>
                        );
                      })}
                      <div>
                        <Label title="Seating Capacity (With Driver)" />
                        <TextInput
                          id="seating_capacity"
                          name="seating_capacity"
                          is_number={true}
                          handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                          value={fields.seating_capacity}
                        />
                        {errors.seating_capacity ? <Label title="seating_capacity" fieldError={errors.seating_capacity} /> : null}
                      </div>
                      <div>
                        <Label title="Order No." />
                        <TextInput
                          id="order_no"
                          name="order_no"
                          is_number={true}
                          handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                          value={fields.order_no}
                        />
                        {errors.order_no ? <Label title="order_no" fieldError={errors.order_no} /> : null}
                      </div>
                      <div>
                        <Label title="Status" />
                        <Dropdown
                          id="status"
                          name="status"
                          title="status"
                          value={fields.status}
                          status={options}
                          handleChange={(field, e) => setFields({ ...fields, [field]: e.target.value })}
                        />
                        {errors.status ? <Label title="status" fieldError={errors.status} /> : null}
                      </div>
                      <div>
                        <Label title="Icon" />
                        <FileInput
                          id="icon"
                          name="icon"
                          value={fields.icon}
                          handleChange={(field, e) => {
                            setIcon(e.target.files[0]);
                            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) });
                          }}
                          cancleImage={cancleImage}
                        />
                        {errors.code ? <Label title="code" fieldError={errors.code} /> : null}
                      </div>
                    </div>
                    <div class="table-responsive py-3 px-3">
                      <table class="table-outline mb-0  d-sm-table table table-hover">
                        <thead class="thead-light">
                          <tr>
                            <th
                              colspan="5"
                              className="bg-skin-primary text-skin-white border-transparent border"
                              style={{ borderColor: 'rgb(164, 183, 193)' }}
                            >
                              Fare Estimate
                            </th>
                            <th
                              colspan="3"
                              className="bg-skin-primary text-skin-white border-transparent border"
                              style={{ borderColor: 'rgb(164, 183, 193)' }}
                            >
                              Schedule Ride
                            </th>
                          </tr>
                          <tr className="bg-gray">
                            <th width="200px">
                              <Label title="Base Fare" />
                            </th>
                            <th width="200px">
                              <Label title="Per KM Fare" />
                            </th>
                            <th width="200px">
                              <Label title="Per Min Fare" />
                            </th>
                            <th width="200px">
                              <Label title="Min.Base KM" />
                            </th>
                            <th width="200px">
                              <Label title="Min.Base Fare" />
                            </th>
                            <th width="200px">
                              <Label title="Schedule Ride Base Fare" />
                            </th>
                            <th width="200px">
                              <Label title="Wait Fees" />
                            </th>
                            <th width="200px">
                              <Label title="Free Wait Mins" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="paddy">
                            <td>
                              <TextInput
                                id="base_fare"
                                name="base_fare"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.base_fare}
                              />
                              {errors.base_fare ? <Label title="base_fare" fieldError={errors.base_fare} /> : null}
                            </td>
                            <td>
                              <TextInput
                                id="per_km_fare"
                                name="per_km_fare"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.per_km_fare}
                              />
                              {errors.per_km_fare ? <Label title="per_km_fare" fieldError={errors.per_km_fare} /> : null}
                            </td>
                            <td>
                              <TextInput
                                id="per_min_fare"
                                name="per_min_fare"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.per_min_fare}
                              />
                              {errors.per_min_fare ? <Label title="per_min_fare" fieldError={errors.per_min_fare} /> : null}
                            </td>
                            <td>
                              <TextInput
                                id="min_base_km"
                                name="min_base_km"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.min_base_km}
                              />
                              {errors.min_base_km ? <Label title="min_base_km" fieldError={errors.min_base_km} /> : null}
                            </td>
                            <td>
                              <TextInput
                                id="min_base_fare"
                                name="min_base_fare"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.min_base_fare}
                              />
                              {errors.min_base_fare ? <Label title="min_base_fare" fieldError={errors.min_base_fare} /> : null}
                            </td>
                            <td>
                              <TextInput
                                id="min_base_fare_for_scheduled_rides"
                                name="min_base_fare_for_scheduled_rides"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.min_base_fare_for_scheduled_rides}
                              />
                              {errors.min_base_fare_for_scheduled_rides ? (
                                <Label title="min_base_fare_for_scheduled_rides" fieldError={errors.min_base_fare_for_scheduled_rides} />
                              ) : null}
                            </td>
                            <td>
                              <TextInput
                                id="wait_fees"
                                name="wait_fees"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.wait_fees}
                              />
                              {errors.wait_fees ? <Label title="wait_fees" fieldError={errors.wait_fees} /> : null}
                            </td>
                            <td>
                              <TextInput
                                id="free_minutes_before_wait_fees_apply"
                                name="free_minutes_before_wait_fees_apply"
                                is_number={true}
                                handleChange={(field, e) => setPriceFields({ ...priceFields, [field]: e.target.value })}
                                value={priceFields.free_minutes_before_wait_fees_apply}
                              />
                              {errors.free_minutes_before_wait_fees_apply ? (
                                <Label title="free_minutes_before_wait_fees_apply" fieldError={errors.free_minutes_before_wait_fees_apply} />
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ButtonSection path={'/ride-option'} handleSubmit={handleSubmit} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {' '}
          <Loader />
        </>
      )}
    </>
  );
}
